import { useEffect, useReducer, useState } from 'react'
import styles from './BillingTab.module.css'
import Button from '../../components/design/Button'
import GTable from '../design/components/GTable'
import { filtersReducer, formatDate, getOrgURLParam, validateEmail } from '../../utility-functions'
import { JService } from '../api-service/ApiService'
import { useDispatch, useSelector } from 'react-redux'
import { CustomInput } from '../design/components/customComponents'
import { deleteIcon, topNavAccountPlusIcon } from '../../utility-functions/assetLoader'
import { saveSelectedOrg, setAllOrgsDetails } from '../features/account/account'
import BillingAddressForm from './BillingAddressForm'
import { enqueueSnackbar } from 'notistack'
import { Filters } from '../dashboard/Filters'
import axios from 'axios'

const formatCalendarDate = (date) => {
    return `${new Date(date).getFullYear()}-${(
      "0" +
      (new Date(date).getMonth() + 1)
    ).slice(-2)}-${("0" + new Date(date).getDate()).slice(-2)}`;
  };
const emptyState={searchText:''}
const BillingTab=({billingAddress,billingContacts,handleSaveBillingContacts,refetchData,gstin,activeTab})=>{
    const API_BASE_URL=process.env.REACT_APP_BETA

    const reduxAccount=useSelector(state=>state?.account?.value)
    const reduxCredits=useSelector(state=>state?.account?.value?.credits)
    const reduxSubscription=useSelector(state=>state?.account?.value?.subscription)

    const dispatch=useDispatch()
    
    const {line1,line2,city,country,state,zip}=billingAddress?billingAddress:{}

    const [companyName,setCompanyName]=useState({value:'',error:null})
    const [gstNumber,setGstNumber]=useState(gstin)
    const [address, setAddress]=useState({line1,line2,city,zip,state,country:country || 'IN'})
    const [contactsList,setContactsList]=useState({})
    const [contactFieldsCount,setContactFieldsCount]=useState(0)
    const [saveContactsDisabled,setSaveContactsDisabled]=useState(true)
    const [addressModified,setAddressModified]=useState(false)
    const [companyNameModified,setCompanyNameModified]=useState(false)
    const [billingHistory,setBillingHistory]=useState([])
    const [rows,setRows]=useState([])
    const [pageData,setPageData]=useState({rowCount:0,prev:null,next:null,curPage:1,pageSize:10})
    const [calendar,setCalendar]=useState({date_start: formatCalendarDate(Date.now() - 7*86400000), //  1 week data
    date_end: formatCalendarDate(Date.now())})
    const [range,setRange]=useState('')
    const [gTablePageNumber, setGTablePageNumber] = useState(0);
    const [showLoader,setShowLoader]=useState(false)
    const [availableData, dispatchAvailableData] = useReducer(
      filtersReducer,
      emptyState
    );

    useEffect(()=>{
        if(!reduxAccount) return;
        const selectedOrg=reduxAccount?.selectedOrg
        if(selectedOrg){
        const selectedOrgDetails=reduxAccount.allOrgs?.find(org=>org.id===selectedOrg)
        setCompanyName({value:selectedOrgDetails?.company,error:null})
        }else{
            setCompanyName({value:reduxAccount?.allOrgs[0]?.company || '',error:null})
        }

    },[reduxAccount])

    useEffect(()=>{
        initContactsList()
    },[billingContacts])

    const initContactsList=()=>{
        if(!billingContacts) return;
        const temp={}
        billingContacts.forEach((elem,index)=>{
            temp[`contact${index}`]={value:elem,error:null}
        })
        setContactsList(temp)
        setContactFieldsCount(Object.keys(temp)?.length)
    }
    const handleCompanyNameChange=(e,val)=>{
        if(e==='focus') {
          setCompanyName(prev=>({...prev,error:null}))
          return;
        }
        else if(e==='value'){
          setCompanyName({value:val,error:null})
          return;
        }
        if(!companyNameModified) setCompanyNameModified(true)
        setCompanyName(prev=>({...prev,value:e.target.value}))
    }
    const handleAddressChange=(e,field)=>{
        
        if(!addressModified) setAddressModified(true)
        if(field==='country'){
          setAddress(prev=>({...prev,country:e}))
          return;
        }
        const name=e.target.name
        setAddress(prev=>({...prev,[name]:e.target.value}))
    }
    const handleGstNumberChange=(val)=>{
      if(!addressModified) setAddressModified(true)
      setGstNumber(val)
    }
    const handleContactListChange=(e)=>{
        if(saveContactsDisabled) setSaveContactsDisabled(false)
        const name=e.target.name
        const value=e.target.value
        setContactsList(prev=>({...prev,[name]:{...prev[name],value:value}}))
    }
    const handleDeleteContact=(val)=>{
        const temp={...contactsList}
        delete temp[val]
        setContactsList(temp)
        if(saveContactsDisabled) setSaveContactsDisabled(false)

    }
    const handleAddContactField=()=>{
        if(contactsList && Object.keys(contactsList)?.length>=5) return;
        setContactsList(prev=>({...prev,[`contact${contactFieldsCount?contactFieldsCount:1}`]:{value:'',error:null}}))
        setContactFieldsCount(prev=>prev+1)
    }
    const handleFocus=()=>{

    }
    const handleUpdateBillingAddress=()=>{
        let _companyNameModified=companyNameModified
        let _addressModified=addressModified
        setAddressModified(false)
        setCompanyNameModified(false)
        if(_companyNameModified){
            setCompanyNameModified(false)
            let errors=null
            JService.patch(
                API_BASE_URL + `/api/v1/auth/update-organization/${getOrgURLParam()}`,
                JSON.stringify({ 
                  company: companyName.value    
                 })
              )
                .then((res) => {
                  if (!res?.success){
                    if(res?.details) errors=res?.details
                    throw new Error(
                      res?.message || res?.detail || "Could not update company details"
                    );
                  }
				  enqueueSnackbar(res?.message || "Updated company details", { variant: "success"})
                  
                  let allOrgsData = [
                    ...reduxAccount?.allOrgs?.filter((ao) => ao?.id !== res.data?.id),
                    res.data,
                  ];
                  dispatch(setAllOrgsDetails(allOrgsData));

                  // dispatch(setAllOrgsDetails([res?.data]));
                  // dispatch(saveSelectedOrg(res?.data?.id));
                })
                .catch((err) =>{
                  if(errors && errors?.company){
                    setCompanyName(prev=>({...prev,error:Array.isArray(errors?.company)?errors?.company[0]:errors.company}))
                  }
                  else enqueueSnackbar(err?.message, { variant: "error"})
            	})
        }
        if(_addressModified){
        const payload={
          "address": {
            line1:address.line1 || '',
            line2:address.line2 || '',
            zip:address.zip || '',
            city:address.city || '',
            state:address.state || '',
            country:address.country || ''
        },
        "gstin":gstNumber || null
      }
        JService.patch(API_BASE_URL+`/api/v1/subscriptions/subscription/${getOrgURLParam()}`,JSON.stringify(payload))
        .then((res)=>{
            if(!res?.success)
                throw new Error(res?.message || 'Failed to update billing address')
			    enqueueSnackbar(res?.message || 'Successfully updated the billing information', { variant: "success"})
            
        })
        .catch(err=>{
			      enqueueSnackbar(err?.message || 'Failed to update the billing information', { variant: "error"})
            setAddress(billingAddress)
        })
        .finally(()=>{
          setTimeout(()=>{
            refetchData()
          },1000)
         
        })
    }
    }
    const validateInput=()=>{
        let isValid=true
        const prev={...contactsList}
        const temp={}
            Object.keys(prev)?.forEach(contact=>{
                if(!prev[contact]?.value || prev[contact]?.value?.trim()?.length===0 || !validateEmail(prev[contact]?.value)){
                    temp[contact]={value:'',error:'Please enter a valid email address'}
                    
                    isValid=false
                }
                else
                    temp[contact]={...prev[contact]}
            })
        setContactsList(temp)
        return isValid
    }
    const handleUpdateBillingContacts=()=>{
        setSaveContactsDisabled(true)
        const isValid=validateInput() 
        if(isValid){
            const payload={"contacts":Object.keys(contactsList)?.map(contact=>contactsList[contact]?.value)}
            //handleSaveBillingContacts(payload)
            JService.patch(`/api/v1/subscriptions/subscription/${getOrgURLParam()}`,JSON.stringify(payload))
            .then((res)=>{
                if(!res?.success)
                    throw new Error(res?.message || 'Failed to update billing contacts')
				enqueueSnackbar(res?.message || 'Successfully updated the billing information', { variant: "success"})
     
            })
            .catch(err=>{
				enqueueSnackbar(err?.message || 'Failed to update the billing information', { variant: "error"})
                initContactsList()
            })
            .finally(()=>{
              setTimeout(()=>{
                refetchData()
              },1000)
             })
        }
    }
    const handlePageChange=(val)=>{
        setPageData(prev=>({...prev,curPage:val+1}))
    }
    const handlePageSizeChange=(pageSize)=>{
        setPageData(prev=>({...prev,pageSize:pageSize,curPage:1}))
        setGTablePageNumber(0)
    }
    const handleFilterApply = (filterName, val,range) => {
        if (filterName === "Date") {
         setPageData(prev=>({...prev,curPage:1}))
         setGTablePageNumber(0)
         setCalendar({date_start:val?.date_start,date_end:val?.date_end});
         setRange(range)
        }
      };
    const onSearch = (searchText) => {
       if(!searchText || !searchText.trim()?.length){
          setRows(billingHistory);
          return
        } 
        const searchTextLower = searchText?.toLowerCase();
        const temp = billingHistory?.filter((row) =>
          row.document_number?.toLowerCase().includes(searchTextLower)
        );
        setRows(temp)
      };
    const fetchBillingHistory=(signal)=>{
        setShowLoader(true)
        JService.get(API_BASE_URL+`/api/v1/subscriptions/billing-history/${getOrgURLParam()}?date_start=${calendar.date_start}&date_end=${calendar.date_end}&page=${pageData?.curPage || 1}&page_size=${pageData?.pageSize}`, {}, {signal})
        .then((res)=>{
            if(!('success' in res)) return;
            if(!res?.success) throw new Error(res?.message || 'Could not retrieve billing history')
            setShowLoader(false);
            setBillingHistory(res?.data?.results)
            setRows(res?.data?.results)
            setPageData(prev=>({...prev,rowCount:res?.data?.count,next:res?.data?.next,prev:res?.data?.prev}))
        })
        .catch(err=>{
          setShowLoader(false);
        })
    }

    const handleDownloadDocument = async (e, itemType, itemId) => {
      let res = await axios.get(API_BASE_URL + `/api/v1/subscriptions/document/?item_type=${itemType}&id=${itemId}`, {
        responseType: 'blob', 
        withCredentials: true
      })
      
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `FreJun_${itemType}_${itemId}.pdf`);
      document.body.appendChild(link);
      link.click();
    }

    useEffect(()=>{
        if(reduxSubscription?.status === 'TRIAL') return;
        const controller = new AbortController();
        fetchBillingHistory(controller.signal);

        return () => controller.abort();
    },[pageData.pageSize,pageData.curPage,calendar,reduxSubscription?.status])

    useEffect(() => {
        onSearch(availableData?.searchText);
      }, [availableData?.searchText]);

    const cols=[
        {field:'date',headerName:'Date',flex:17.6,width:200,renderCell:(param)=><span  className="t7" style={{padding:'16px 14px'}}>{formatDate(param?.row?.date)}</span>,headerClassName:styles.header},
        {field:'document_number', headerName:'Document number',flex:31.6,width:360,headerClassName:styles.header, renderCell:(params)=><span className="t7">{params?.row?.document_number}</span>},
        {field:'amount',headerName:'Amount',flex:14,width:160,headerClassName:styles.header,renderCell:(params)=><span className="t7" >{reduxCredits?.currency==='INR'?'₹':'$'}{params?.row?.amount}</span>},
        {field:'status',headerName:'Status',flex:14,width:160,headerClassName:styles.header,renderCell:(param)=><span className='t7 regular-font' style={{padding:'6px 10px',lineHeight:'14px',borderRadius:'4px',border:`1px solid ${param?.row?.status==='Paid'?'#d3f8df':param?.row?.status==='Failed'?'#ffe4e8':'#fef7c3'}`,backgroundColor:param?.row?.status==='Paid'?'#edfcf2':param?.row?.status==='Failed'?'#fff1f3':'#fefbe8'}}>{param?.row?.status}</span>},
        {field:'item_type',headerName:'Download',flex:22.5,headerClassName:styles.header,renderCell:(params)=><button onClick={(e) => handleDownloadDocument(e, params?.row?.item_type, params?.row?.id)} className={`${styles.downloadItemType} t7 regular-font`}>{params.row?.item_type?.split('_')?.join(' ')}</button>}
    ]
    return (
        <div className={styles.container}>
            <section>
            <h2 className='t5 medium-font'>Billing information</h2>
            <form>
                <BillingAddressForm activeTab={activeTab} companyName={companyName} handleCompanyNameChange={handleCompanyNameChange} address={address} handleAddressChange={handleAddressChange} gstNumber={gstNumber} handleGstNumberChange={handleGstNumberChange}/>
                <Button hierarchy='green' buttonText='Save changes' onClick={handleUpdateBillingAddress} disabled={!companyNameModified && !addressModified} styleOverride={{height:'36px', fontSize:'14px',fontWeight:'500',padding:'10px 16px'}}/>
            </form>
            </section>
            {reduxSubscription?.status!=='TRIAL' && 
              <><section>
                <h2 className='t5 medium-font'>Billing contacts</h2>
                <p className='t7 regular-font'>Send invoices, receipts and other renewal notifications to these email addresses.</p>
                {contactsList && Object.keys(contactsList)?.map((elem)=>{
                   return <div className={styles.contactContainer} key={elem}>
                            <CustomInput inputProps={{autocomplete:'off','aria-autocomplete':'none'}} style={{marginBottom:'12px'}} label='' value={contactsList[elem]} name={elem} handleChange={handleContactListChange} handleFocus={handleFocus} type='email'/>
                            <span onClick={()=>handleDeleteContact(elem)}>{deleteIcon()}</span>
                          </div>
                })}
                <button disabled={contactsList && Object.keys(contactsList)?.length>=5} className={`${styles.addContactBtn} t7 medium-font`} onClick={handleAddContactField}><span style={{marginTop:'2px'}}>{topNavAccountPlusIcon(contactsList && Object.keys(contactsList)?.length>=5?'#c6ccc8':'#2eb258')}</span>Add contact</button>
                <Button hierarchy='green' buttonText='Save changes' onClick={handleUpdateBillingContacts} disabled={saveContactsDisabled} styleOverride={{height:'36px', fontSize:'14px',fontWeight:'500',padding:'10px 16px',margin:'40px 0'}}/>
            </section>
            <section>
                <h2 className='t5 medium-font'>Billing history</h2>
                <Filters
                    filtersList={[]}
                    dateFilter={true}
                    defaultDateRange={7}
                    onApply={handleFilterApply}
                    fromScreen={"billing"}
                />
                <GTable 
                    leftHeader={{isSearch:true,filters:[],}} 
                    rightHeader={{}}
                    rows={rows} columns={cols} 
                    useMuiTable={true} isLoading={showLoader} fromScreen='billing' 
                    rowCount={pageData.rowCount || 0}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    setGTablePageNumber={setGTablePageNumber}
                    availableData={availableData}
                    dispatchAvailableData={dispatchAvailableData}
                    onSearch={onSearch}
                    paginationMode='server'
                    onRowClick={() => null}
                    />

            </section></>}
        </div>
    )
}

export default BillingTab