import { ListItem, ListItemText, List, Backdrop } from "@mui/material";
import { useState, useEffect, useRef, useMemo } from "react";
import colors from "../design/colors";
import Button from "../design/Button";
import "./filters.css";
import GFilters from "../design/components/GFilters";
import { callLogsHeaderCalendar } from "../../utility-functions/assetLoader";
import { DateRangePicker } from "rsuite";
import { changeDateFormat, formatDate } from "../../utility-functions";
//format date can take both dd-mm-yyyy and yyyy-mm-dd.
import { hasPermission } from "../features/permission/auth";

const MILLISECONDS_IN_ONE_DAY = 86400000;

const toYYYYMMDDFromDate = (date) => {
  //converts date object into yyyy-mm-dd
  return `${new Date(date).getFullYear()}-${(
    "0" +
    (new Date(date).getMonth() + 1)
  ).slice(-2)}-${("0" + new Date(date).getDate()).slice(-2)}`;
};

const toDateFromDDMMYYYY = (dateStr) => {
  //converts dd-mm-yyyy into date object
  const [day, month, year] = dateStr.split("-").map(Number);
  return new Date(year, month - 1, day);
};

const compareTimes = (time1, time2) => {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  if (hours1 === hours2) return minutes1 - minutes2;
  return hours1 - hours2;
};

const areCalendarStatesEqual = (currentState, defaultState) => {
  for(let key in currentState) {
    if(!key.includes("unformatted")) {
      if(currentState[key] !== defaultState[key]) {
        return false;
      }
    }
  }
  return true;
}

const isValidTime = (value) => {
  return /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/.test(value);
};

const isValidDate = (value) => {
  return /^\d{2}-\d{2}-\d{4}$/.test(value);
};

const getDefaultCalendarState = (range) => {
  return {
    date_start: toYYYYMMDDFromDate(Date.now() - range * MILLISECONDS_IN_ONE_DAY),
    date_end: toYYYYMMDDFromDate(Date.now()),
    time_start: "00:00:00",
    time_end: "23:59:59",
    date_start_unformatted: Date.now() - range * MILLISECONDS_IN_ONE_DAY,
    date_end_unformatted: Date.now(),
  };
}

export const Filters = ({
  onApply,
  availableData = null,
  dispatchAvailableData = () => null,
  fromScreen,
  filtersList,
  dateFilter = true,
  defaultCalendarState,
  defaultDateRange,
  ...props
}) => {
  const { afterToday } = DateRangePicker;

  const dateRangeOptions = [
    "Custom",
    "Today",
    "Yesterday",
    "This week",
    "Last week",
    "This month",
    "Last month",
    "This year",
    "Last year",
  ];
  const datePickerContainerRef = useRef(null);
  const fromDateRef = useRef(null);
  const fromDateCursorRef = useRef(null);
  const toDateRef = useRef(null)
  const toDateCursorRef = useRef(null)
  const fromTimeCursorRef = useRef(null);
  const toTimeCursorRef = useRef(null);
  const fromTimeRef = useRef(null)
  const toTimeRef = useRef(null)

  //States
  const [activeFilter, setActiveFilter] = useState(null);
  const [selectedRange, setSelectedRange] = useState( "");
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [calendarDisabled, setCalendarDisabled] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [calendar, setCalendar] = useState( defaultCalendarState ?? getDefaultCalendarState(defaultDateRange));
  const [fromDate, setFromDate] = useState(
    changeDateFormat(calendar?.date_start)
  );
  const [toDate, setToDate] = useState(changeDateFormat(calendar?.date_end));
  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);
  const [fromDateFocused, setFromDateFocused] = useState(false);
  const [toDateFocused, setToDateFocused] = useState(false);
  const [fromTime, setFromTime] = useState(calendar?.time_start?.slice(0, 5));
  const [toTime, setToTime] = useState(calendar?.time_end?.slice(0, 5));
  const [fromTimeError, setFromTimeError] = useState("");
  const [toTimeError, setToTimeError] = useState("");

  //Handlers
  const dateFilterHandler = () => {
    if (props.calendarDisabled) return;
    if (activeFilter) {
      setActiveFilter(null);
      return;
    }
    setActiveFilter("Date");
  };

  const clearDateFilterHandler = () => {
    setSelectedRange(null);
    setFromError(false);
    setToError(false);
    setFromTimeError(false);
    setToTimeError(false);
    setFromTime("00:00");
    setToTime("23:59");

    const defaultState = getDefaultCalendarState(defaultDateRange);
  
    setFromDate(changeDateFormat(defaultState.date_start));
    setToDate(changeDateFormat(defaultState.date_end));
  
    setCalendar(defaultState);
  
    setActiveFilter(null);
  
    onApply("Date", defaultState, null);
  };

  const applyDateFilterHandler = () => {
    if (
      fromError ||
      toError ||
      fromTimeError ||
      toTimeError ||
      !fromDate ||
      !toDate ||
      !fromTime ||
      !toTime
    )
      return;
    setActiveFilter(null);
    const btns = document.querySelectorAll(
      ".rs-picker-daterange-panel .rs-btn.rs-btn-primary.rs-btn-sm"
    );
    let okButton = null;
    for (let btn of btns) {
      if (btn.innerHTML?.startsWith("OK")) {
        okButton = btn;
        break;
      }
    }
    if (okButton) okButton.click();
    onApply("Date", calendar, selectedRange);
  };

  const dateBlurHandler = (e, inputField) => {
    if (inputField === "from" && fromDate) {
      setFromDateFocused(false);
      if (!fromError) {
        let startDate = toDateFromDDMMYYYY(fromDate);
        const today = new Date();
        if (startDate > today) {
          setFromDate(changeDateFormat(toYYYYMMDDFromDate(today)));
          startDate = today;
        }
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(startDate),
          date_start_unformatted: startDate,
        }));
      }
    } else if (inputField === "to" && toDate) {
      setToDateFocused(false);
      if (!toError) {
        let endDate = toDateFromDDMMYYYY(toDate);
        const today = new Date();
        if (endDate > today) {
          setToDate(changeDateFormat(toYYYYMMDDFromDate(today)));
          endDate = today;
        }
        setCalendar((prev) => ({
          ...prev,
          date_end: toYYYYMMDDFromDate(endDate),
          date_end_unformatted: endDate,
        }));
        setDateRange((prev) => [...prev, endDate]);
      }
    }
  };

  const timeBlurHandler = (field) => {
    if (field === "from" && !fromTimeError) {
      setCalendar((prev) => ({
        ...prev,
        time_start: (fromTime + ":00").slice(0, 8),
      }));
    } else if (field === "to" && !toTimeError) {
      setCalendar((prev) => ({
        ...prev,
        time_end: (toTime + ":59").slice(0, 8),
      }));
    }
  };
  const formatDateInput = (value) => {
    let digits = value.replace(/\D/g, "").slice(0, 8);
    const day = digits.slice(0, 2);
    const month = digits.slice(2, 4);
    const year = digits.slice(4, 8);
    return [day, month, year].filter(Boolean).join("-");
  };
  
  const fromDateChangeHandler = (e) => {
    let cursorPos = fromDateRef.current?.selectionStart;
    fromDateCursorRef.current = ([3, 6].includes(cursorPos) && e.target.value?.length < 7) ? cursorPos + 1 : cursorPos;
    const formatted = formatDateInput(e.target.value);
    setFromDate(formatted);
  };

  const toDateChangeHandler = (e) => {
    let cursorPos = toDateRef.current?.selectionStart;
    toDateCursorRef.current = ([3, 6].includes(cursorPos) && e.target.value?.length < 7) ? cursorPos + 1 : cursorPos;
    const formatted = formatDateInput(e.target.value);
    setToDate(formatted);
  };

  const formatTimeInput = (value, cursorPos) => {
    let formatted = value.replace(/[^0-9]/g, ""); 
    let newCursorPos = cursorPos;
  
    if (formatted.length > 2) {
      formatted = `${formatted.slice(0, 2)}:${formatted.slice(2)}`;
  
      if (cursorPos === 3 && value.length < 4) {
        newCursorPos += 1;
      }
    }
  
    if (formatted.length > 5) {
      formatted = formatted.slice(0, 5);
    }
  
    return { formatted, newCursorPos };
  };

const fromTimeChangeHandler = (e) => {
  const cursorPos = fromTimeRef.current?.selectionStart;
  const inputValue = e.target.value;

  const { formatted, newCursorPos } = formatTimeInput(inputValue, cursorPos);
  fromTimeCursorRef.current = newCursorPos; 
  setFromTime(formatted);
};

const toTimeChangeHandler = (e) => {
  const cursorPos = toTimeRef.current?.selectionStart;
  const inputValue = e.target.value;

  const { formatted, newCursorPos } = formatTimeInput(inputValue, cursorPos);
  toTimeCursorRef.current = newCursorPos; 
  setToTime(formatted);
};

  const selectDateHandler = (date) => {
    if (!dateRange[0] || (dateRange[0] && dateRange[1])) {
      setDateRange([date]);
      setFromDate(changeDateFormat(toYYYYMMDDFromDate(date)));
      setToDate("");
      setCalendar((prev) => ({
        ...prev,
        date_start: toYYYYMMDDFromDate(date),
        date_start_unformatted: date,
        date_end: "",
        date_end_unformatted: null,
      }));
      console.log("calendar from 1st=", calendar);
    } else {
      const updatedRange = [dateRange[0], date].sort((a, b) => a - b);
      setDateRange(updatedRange);
      setFromDate(changeDateFormat(toYYYYMMDDFromDate(updatedRange[0])));
      setFromError("");
      setToError("");
      setToDate(changeDateFormat(toYYYYMMDDFromDate(updatedRange[1])));
      setCalendar((prev) => ({
        ...prev,
        date_start: toYYYYMMDDFromDate(updatedRange[0]),
        date_end: toYYYYMMDDFromDate(updatedRange[1]),
        date_start_unformatted: updatedRange[0],
        date_end_unformatted: updatedRange[1],
      }));
      console.log("calendar from 2nd=", calendar);
    }
  };

  //Effects

  useEffect(() => {
    const fromDateIsValid = isValidDate(fromDate);
    const toDateIsValid = isValidDate(toDate);
    if (!fromDateIsValid) {
      setFromError(true);
    } else {
      setFromError(false);
    }
    if (!toDateIsValid) {
      setToError(true);
    } else if (
      fromDateIsValid &&
      toDateIsValid &&
      toDateFromDDMMYYYY(fromDate) > toDateFromDDMMYYYY(toDate)
    ) {
      setToError(true);
    } else {
      setToError(false);
    }
    toDateRef.current?.setSelectionRange(toDateCursorRef.current, toDateCursorRef.current)
    fromDateRef.current?.setSelectionRange(fromDateCursorRef.current, fromDateCursorRef.current)
  }, [fromDate, toDate]);

useEffect(() => {
    const fromTimeIsValid = isValidTime(fromTime);
    const toTimeIsValid = isValidTime(toTime);
    if (!fromTimeIsValid) {
      setFromTimeError(true);
    } else {
      setFromTimeError(false);
    }
    if (!toTimeIsValid) {
      setToTimeError(true);
    } else if (
      !fromError &&
      !toError &&
      fromTimeIsValid &&
      toTimeIsValid &&
      fromDate === toDate &&
      compareTimes(fromTime, toTime) > 0
    ) {
      setToTimeError(true);
    } else {
      setToTimeError(false);
    }
    fromTimeRef.current?.setSelectionRange(fromTimeCursorRef.current, fromTimeCursorRef.current);
    toTimeRef.current?.setSelectionRange(toTimeCursorRef.current, toTimeCursorRef.current);
  }, [fromTime, toTime, fromDate, toDate, fromError, toError]);

  useEffect(() => {
    if (props.calendarDisabled) return;

    const todaysDayNumber = new Date().getDay();
    const one_day = MILLISECONDS_IN_ONE_DAY;
    const today = Date.now();

    switch (selectedRange) {
      case "Today":
        const _date_start = new Date(today);
        _date_start.setHours(0, 0, 0);
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(_date_start),
          date_end: toYYYYMMDDFromDate(new Date(today)),
          date_start_unformatted: _date_start,
          date_end_unformatted: new Date(today),
        }));
        setCalendarDisabled(true);
        break;
      case "Yesterday":
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(new Date(today - one_day)),
          date_end: toYYYYMMDDFromDate(new Date(today - one_day)),
          date_start_unformatted: new Date(today - one_day),
          date_end_unformatted: new Date(today - one_day),
        }));
        setCalendarDisabled(true);
        break;
      case "This week":
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(
            new Date(today - (todaysDayNumber - 1) * one_day)
          ),
          date_end: toYYYYMMDDFromDate(today),
          date_start_unformatted: new Date(
            today - (todaysDayNumber - 1) * one_day
          ),
          date_end_unformatted: today,
        }));
        setCalendarDisabled(true);
        break;
      case "Previous week":
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(today - 7 * one_day),
          date_end: toYYYYMMDDFromDate(today),
          date_start_unformatted: today - 7 * one_day,
          date_end_unformatted: today,
        }));
        break;
      case "Last week":
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(today - 7 * one_day),
          date_end: toYYYYMMDDFromDate(today - one_day),
          date_start_unformatted: today - 7 * one_day,
          date_end_unformatted: today - one_day,
        }));
        setCalendarDisabled(true);
        break;
      case "This month":
        const firstDayOfMonth = new Date(new Date().setDate(1));
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(firstDayOfMonth),
          date_end: toYYYYMMDDFromDate(today),
          date_start_unformatted: firstDayOfMonth,
          date_end_unformatted: today,
        }));
        setCalendarDisabled(true);
        break;
      case "Last month":
        const firstDayOfLastMonth = new Date(
          new Date().setMonth(new Date().getMonth() - 1, 1)
        );
        const todayDate = new Date();
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(firstDayOfLastMonth),
          date_end: toYYYYMMDDFromDate(
            new Date(todayDate.getFullYear(), todayDate.getMonth(), 0)
          ),
          date_start_unformatted: firstDayOfLastMonth,
          date_end_unformatted: new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            0
          ),
        }));
        setCalendarDisabled(true);
        break;
      case "This year":
        const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(firstDayOfYear),
          date_end: toYYYYMMDDFromDate(today),
          date_start_unformatted: firstDayOfYear,
          date_end_unformatted: today,
        }));
        setCalendarDisabled(true);
        break;
      case "Last year":
        const firstDayOfLastYear = new Date(new Date().getFullYear() - 1, 0, 1);
        const lastDayOfLastYear = new Date(
          new Date().getFullYear() - 1,
          11,
          31
        );
        setCalendar((prev) => ({
          ...prev,
          date_start: toYYYYMMDDFromDate(firstDayOfLastYear),
          date_end: toYYYYMMDDFromDate(lastDayOfLastYear),
          date_start_unformatted: firstDayOfLastYear,
          date_end_unformatted: lastDayOfLastYear,
        }));
        setCalendarDisabled(true);
        break;
      case "Custom range":
        setCalendarDisabled(false);
        break;
      default:
        setCalendarDisabled(true);
        break;
    }
  }, [selectedRange]);

  useEffect(() => {
    if (calendar) {
      const { date_start, date_end } = calendar;
      console.log("calendar from use effect=", calendar);
      if (date_start && date_end) {
        setDateRange([
          new Date(`${date_start} ${calendar.time_start}`),
          new Date(`${date_end} ${calendar.time_end}`),
        ]);
      }
      if (selectedRange !== "Custom") {
        setFromDate(changeDateFormat(date_start));
        setToDate(changeDateFormat(date_end));
      }
    }
  }, [calendar]);

  useEffect(() => {
    if(areCalendarStatesEqual(calendar, getDefaultCalendarState(defaultDateRange))) {
      setIsDateFilterApplied(false);
    } else {
      setIsDateFilterApplied(true);
    }

  }, [calendar, defaultDateRange])

  const allowedFiltersList = useMemo(() => {
    return filtersList.filter((elem) =>
      "permission" in elem ? hasPermission(null, elem["permission"]) : true
    );
  }, [filtersList]);

  return (
    <div className="dashboard_filter-row-container" style={props?.style}>
      <div className="dashboard_filter-row">
        <GFilters
          fromScreen={fromScreen}
          isDateFilterApplied={isDateFilterApplied}
          clearDateFilterHandler={clearDateFilterHandler}
          availableData={availableData}
          dispatchAvailableData={dispatchAvailableData}
          filters={allowedFiltersList}
          dateDropdownHandler={{
            isDateDropdownVisible: Boolean(activeFilter),
            dateFilterHandler,
          }}
        />
      </div>
      {dateFilter && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div
            className="filter_container"
            style={{
              color: props.calendarDisabled ? colors.nc_gray_400 : "#3F3F46",
              userSelect: "none",
              cursor: props.calendarDisabled ? "not-allowed" : "pointer",
            }}
            onClick={dateFilterHandler}
          >
            <span style={{ marginRight: "6px" }}>
              {callLogsHeaderCalendar(
                props.calendarDisabled ? colors.nc_gray_400 : colors.nc_gray_900
              )}
            </span>
            <p className="text-sm regular-font">
              {`  ${new Date(
                props.date || calendar.date_start_unformatted
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })} - ${new Date(
                props.date || calendar.date_end_unformatted
              ).toLocaleDateString("en-IN", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}`}
            </p>
          </div>
          {activeFilter === "Date" && (
            <>
              <Backdrop
                sx={{
                  zIndex: 5,
                  "&.MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0)",
                  },
                }}
                open={activeFilter}
                onClick={dateFilterHandler}
              ></Backdrop>
              <div className="dashboard_date-filter-menu">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid #e4e4e7",
                  }}
                >
                  <div className="dashboard_filter-list-container">
                    <List sx={{ paddingTop: "0" }}>
                      {dateRangeOptions.map((range, i) => (
                        <ListItem
                          key={i}
                          sx={{
                            padding: "12px 0px 12 16px",
                            width: "144px",
                            height: "44px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            backgroundColor:
                              selectedRange === range ? "#F1FCF4" : "#fff",
                            color:
                              selectedRange === range ? "#219848" : "#3F3F46",
                            "&:hover": { backgroundColor: "#f7faf8" },
                            "MuiListItemText-root": {},
                          }}
                          onClick={() => setSelectedRange(range)}
                        >
                          <ListItemText
                            sx={{
                              ".MuiTypography-root": {
                                fontSize: "14px",
                                fontWeight: "medium",
                                fontFamily: "Inter",
                                lineHeight: "20px",
                              },
                              margin: 0,
                            }}
                          >
                            {range}
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      position: "relative",
                      width: "328px",
                    }}
                    ref={datePickerContainerRef}
                  >
                    <DateRangePicker
                      container={() => datePickerContainerRef?.current}
                      renderTitle={(date) => (
                        <p>
                          {date.toLocaleDateString("default", {
                            month: "long",
                            year: "numeric",
                          })}
                        </p>
                      )}
                      onSelect={(date) => selectDateHandler(date)}
                      showOneCalendar= {true}
                      value={dateRange}
                      toggleAs={"div"}
                      disabledDate={afterToday()}
                      ranges={[]}
                      open
                      placement="bottomStart"
                      style={{
                        border: `1px solid ${colors.nc_gray_400}`,
                        borderRadius: "6px",
                        padding: "6px",
                      }}
                      disabled={calendarDisabled || false}
                    />
                    <div className="time-filter">
                      <div>
                        <h6 className="text-sm medium-font">From</h6>
                        <input
                          ref={fromDateRef}
                          value={
                            fromDateFocused
                              ? fromDate
                              : fromDate
                                ? formatDate(changeDateFormat(fromDate))
                                : ""
                          }
                          type="text"
                          className="text-sm regular-font"
                          placeholder="dd-mm-yyyy"
                          onFocus={() => {
                            setFromDateFocused(true);
                            setSelectedRange("Custom");
                          }}
                          onChange={fromDateChangeHandler}
                          onBlur={(e) => dateBlurHandler(e, "from")}
                          style={{
                            border: fromError
                              ? "1px solid #E22D20"
                              : "1px solid #E4E4E7",
                          }}
                        />
                        <input
                          ref={fromTimeRef}
                          value={fromTime}
                          type="text"
                          className="text-sm regular-font"
                          style={{
                            border: fromTimeError
                              ? "1px solid #E22D20"
                              : "1px solid #E4E4E7",
                          }}
                          onChange={fromTimeChangeHandler}
                          disabled={
                            !(
                              fromScreen === "dashboard" ||
                              fromScreen === "call-logs"
                            )
                          }
                          onBlur={() => timeBlurHandler("from")}
                          placeholder="hh:mm"
                        />
                      </div>
                      <div>
                        <h6 className="text-sm medium-font">To</h6>
                        <input
                        ref={toDateRef}
                          value={
                            toDateFocused
                              ? toDate
                              : toDate
                                ? formatDate(changeDateFormat(toDate))
                                : ""
                          }
                          type="text"
                          className="text-sm regular-font"
                          placeholder="dd-mm-yyyy"
                          onFocus={() => {
                            setToDateFocused(true);
                            setSelectedRange("Custom");
                          }}
                          onChange={toDateChangeHandler}
                          onBlur={(e) => dateBlurHandler(e, "to")}
                          style={{
                            border: toError
                              ? "1px solid #E22D20"
                              : "1px solid #E4E4E7",
                          }}
                        />
                        <input
                          ref={toTimeRef}
                          value={toTime}
                          type="text"
                          className="text-sm regular-font"
                          style={{
                            border: toTimeError
                              ? "1px solid #E22D20"
                              : "1px solid #E4E4E7",
                          }}
                          onChange={toTimeChangeHandler}
                          disabled={
                            !(
                              fromScreen === "dashboard" ||
                              fromScreen === "call-logs"
                            )
                          }
                          onBlur={() => timeBlurHandler("to")}
                          placeholder="hh:mm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard_date-menu-btn-container">
                  <Button
                    hierarchy="white"
                    variant="outlined"
                    disabled={false}
                    buttonText="Cancel"
                    onClick={clearDateFilterHandler}
                    className="text-sm medium-font"
                    buttonStyle={{ ...buttonStyle, color: "#3F3F46" }}
                  />

                  <Button
                    hierarchy="green"
                    buttonText="Apply"
                    onClick={applyDateFilterHandler}
                    className="text-sm medium-font"
                    buttonStyle={{
                      color: "#FFFFFF",
                      background: "#34CB65",
                      ...buttonStyle,
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
const buttonStyle = {
  border: "1px solid #E4E4E7",
  padding: "8px 14px",
  borderRadius: "6px",
  fontSize: "14px",
  fontWeight: "medium",
  lineHeight: "20px",
};
