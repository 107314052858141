import { createPortal } from "react-dom";
import Button from "../Button";
import classes from "./GBottomSaveBar.module.css";

const GBottomSaveBar = ({ show = false, onCancel, onSave }) => {
  return (
    createPortal(<div
      className={classes.container}
      style={{ display: show ? "flex" : "none" }}
    >
      <Button
        hierarchy="white"
        variant="outlined"
        disabled={false}
        buttonText="Cancel"
        onClick={onCancel}
        className="text-sm medium-font"
        buttonStyle={{
          ...buttonStyle,
          color: "#3F3F46",
          background: "#ffffff",
        }}
      />
      <Button
        hierarchy="green"
        buttonText="Save changes"
        onClick={onSave}
        className="text-sm medium-font"
        buttonStyle={{
          color: "#FFFFFF",
          background: "#34CB65",
          ...buttonStyle,
        }}
      />
    </div>, document.body)
  );
};
const buttonStyle = {
  border: "1px solid #E4E4E7",
  padding: "8px 14px",
  borderRadius: "6px",
  fontSize: "14px",
  fontWeight: "medium",
  lineHeight: "20px",
};
export default GBottomSaveBar;
