import { useSelector } from "react-redux"
import { oauthErrorIcon } from "../../utility-functions/assetLoader"
import Button from "../design/Button"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { JService } from "../api-service/ApiService"
import { enqueueSnackbar } from 'notistack'
import PlanModal1 from "../UpgradePlan/PlanModal1"
import classes from './AccountSuspended.module.css'

const AccountSuspended = () => {
    const reduxSubscription = useSelector(state=>state?.account?.value?.subscription)
    const {canManageBillingSubscription} = useSelector((state) => state?.auth?.permissions);

    const navigate=useNavigate()

    const [loading, setLoading]=useState(false)
    const [planModalVisible,setPlanModalVisible]=useState(false)

    useEffect(()=>{
        if(!isSuspended(reduxSubscription)) {
            navigate('/call-logs',{replace:true})
        }
    }, [reduxSubscription])

    const handlePayInvoice=()=>{
        if(isSuspended(reduxSubscription) && reduxSubscription?.payment_due_date === null){
            setPlanModalVisible(true);
            return;
        }
        setLoading(true)
        JService.get('/api/v1/subscriptions/pay-now/')
        .then((res)=>{
            if(!res?.success){
                throw new Error(res?.message || 'Something went wrong')
            }
            if(res?.url){
                window.location.href=res.url
            }
        })
        .catch((err)=>{
			enqueueSnackbar(err?.message, { variant: "error"})
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    return (
        <div className={`screen-container ${classes.container}`}>
            <div className={classes.infoContainer}>
                <span>{oauthErrorIcon('#e0544c',54,54)}</span>
                <p className="t6 medium-font">{canManageBillingSubscription ? 'Account suspended':'Account under review'}</p>
                <p className={`t7 regular-font ${classes.info}`}>
                    {canManageBillingSubscription ? 'Your account has been suspended.' : 'Your FreJun account is under review.'}<br/>
                    {canManageBillingSubscription ? reduxSubscription?.payment_due_date ? 'To restore, please pay the unpaid invoice' : 'To restore, please upgrade your account' : 'Please contact your account admin.'}<br/>
                    {canManageBillingSubscription && 'or contact chat support for assistance.'}
                </p>
                {canManageBillingSubscription && <Button buttonText={reduxSubscription?.payment_due_date ? 'Pay invoice' : 'Upgrade'} onClick={handlePayInvoice} hierarchy='white' variant='outlined' disabled={false} isLoading={loading}/>}
            </div>
            <PlanModal1 visible={planModalVisible} handleClose={()=>setPlanModalVisible(false)}/>
        </div>
    )
}

const isSuspended = (subscription) => ['INACTIVE', 'PAUSED'].includes(subscription?.status)
export default AccountSuspended