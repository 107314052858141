import { useEffect, useState } from "react";
import Button from "../design/Button";
import styles from "./OverviewTab.module.css";
import AddonUsersModal from "./Modals/AddonUsersModal";
import { useNavigate } from "react-router-dom";
import { modalCloseIcon } from "../../utility-functions/assetLoader";
import PlanModal1 from "../UpgradePlan/PlanModal1";
import { formatDate, getOrgURLParam } from "../../utility-functions";
import ManageLicenseModal from "./Modals/ManageLicenseModal";
import { useDispatch, useSelector } from "react-redux";
import { JService } from "../api-service/ApiService";
import {
  InsufficientCreditsModal,
  PaymentMethodModal,
} from "../design/components/customComponents";
import VirtualNumberModal from "./Modals/VirtualNumberModal";
import AddLicenseModal from "./Modals/AddLicenseModal";
import { setCredits } from "../features/account/account";
import AnimationPage from "../shared/animation-page/AnimationPage";
import { enqueueSnackbar } from "notistack";
import PaymentReminderModal from "../design/components/customComponents";

const OverviewTab = ({
  status,
  plan,
  viewers,
  addons,
  numbers,
  endDate,
  startDate,
  currency,
  changeActiveTab,
  handleUpdateAddonUsers,
  refetchData,
  paymentDueDate,
}) => {
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxCredits = useSelector((state) => state?.account?.value?.credits);
  const { canViewUsers } = useSelector((state) => state?.auth?.permissions);

  const [autodialData, setAutodialData] = useState(
    addons?.find((addon) => addon.name === "AUTODIAL")
  );
  const [browserCallingData, setBrowserCallingData] = useState(
    addons?.find((addon) => addon.name === "BROWSER CALLING")
  );
  const [formattedNumbersData, setFormattedNumbersData] = useState({});
  const [freeNumbersCount, setFreeNumbersCount] = useState(0);
  const [autodialUsersModalVisible, setAutodialUsersModalVisible] =
    useState(false);
  const [browserCallingUsersModalVisible, setBrowserCallingUsersModalVisible] =
    useState(false);
  const [upgradeBoxVisible, setUpgradeBoxVisible] = useState(false);
  const [upgradePlanModalVisible, setUpgradePlanModalVisible] = useState(false);
  const [manageLicenseModalData, setManageLicenseModalData] = useState({
    type: "",
    visible: false,
    licenses: {},
  });
  const [updateLicensePayload, setUpdateLicensePayload] = useState({
    payload: { action: "", quantity: null },
    type: "",
  });
  const [addLicenseModalVisible, setAddLicenseModalVisible] = useState({
    type: "",
    visible: false,
  });
  const [users, setUsers] = useState([]);
  const [insufficientCreditsModalVisible, setInsufficientCreditsModalVisible] =
    useState(false);
  const [paymentMethodModalVisible, setPaymentMethodModalVisible] =
    useState(false);
  const [vnModalVisible, setVnModalVisible] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleUpgradeModal = () => {
    setUpgradePlanModalVisible(true);
  };

  const handleUpgradeBoxClose = () => {
    localStorage.removeItem("account-upgraded-today");
    setUpgradeBoxVisible(false);
  };
  const handleOpenLicenseModal = (type) => {
    console.log(plan, viewers, autodialData);
    if (type === "Plan") {
      setManageLicenseModalData({
        type: "Plan",
        visible: true,
        licenses: plan.licenses,
      });
    } else if (type === "Viewer") {
      if (viewers?.licenses?.total === 0)
        setAddLicenseModalVisible({ type, visible: true });
      else
        setManageLicenseModalData({
          type: "Viewer",
          visible: true,
          licenses: viewers.licenses,
        });
    } else if (type === "Autodial") {
      if (autodialData?.licenses?.total === 0)
        setAddLicenseModalVisible({ type, visible: true });
      else
        setManageLicenseModalData({
          type: "Autodial",
          visible: true,
          licenses: autodialData?.licenses,
        });
    } else if (type === "browser calling") {
      if (browserCallingData?.licenses?.total === 0)
        setAddLicenseModalVisible({ type, visible: true });
      else
        setManageLicenseModalData({
          type: "browser calling",
          visible: true,
          licenses: browserCallingData?.licenses,
        });
    }
  };
  const handleCloseLicenseModal = () => {
    setManageLicenseModalData({
      type: "",
      visible: false,
      licenses: {},
    });
  };
  const updateCreditsData = () => {
    JService.get(
      API_BASE_URL + `/api/v1/subscriptions/credit/${getOrgURLParam()}`
    )
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Failed to retrieve credits");
        dispatch(setCredits(res?.data));
      })
      .catch((err) => {});
  };
  const updateLicense = (
    payWithCredits = "null",
    type = updateLicensePayload?.type,
    payload = updateLicensePayload?.payload
  ) => {
    setPaymentMethodModalVisible(false);
    setInsufficientCreditsModalVisible(false);
    let _payload = { ...payload };
    let url = `/api/v1/subscriptions/plan-licenses/`;
    if (type === "Autodial") {
      _payload["addon"] = "AUTODIAL";
      url = `/api/v1/subscriptions/addon-licenses/`;
    } else if (type === "browser calling") {
      _payload["addon"] = "BROWSER CALLING";
      url = `/api/v1/subscriptions/addon-licenses/`;
    } else if (type === "Viewer") {
      url = `/api/v1/subscriptions/viewer-licenses/`;
    }
    url += `${getOrgURLParam()}`;
    if (payWithCredits === "credits") _payload["pay_with_credits"] = true;
    // else if(_payload['pay_with_credits']) delete _payload['pay_with_credits']
    else if (payWithCredits === "card") _payload["pay_with_card"] = true;
    else if (payWithCredits === "null") {
      if (_payload["pay_with_credits"]) delete _payload["pay_with_credits"];
      if (_payload["pay_with_card"]) delete _payload["pay_with_card"];
    }
    setShowLoader(true);
    JService.post(url, JSON.stringify(_payload))
      .then((res) => {
        if (_payload.action === "add" && res?.url) {
          window.location.href = res?.url;
          return;
        }
        if (
          res?.message === "Insufficient balance" &&
          _payload?.action === "add"
        ) {
          setInsufficientCreditsModalVisible(true);
          return;
        }
        if (!res?.success) {
          throw new Error(res?.message);
        }

        handleCloseLicenseModal();
        enqueueSnackbar(res?.message || "Licenses will be updated shortly", {
          variant: "success",
        });
        setUpdateLicensePayload({
          payload: { action: "", quantity: null },
          type: "",
        });

        setTimeout(() => {
          updateCreditsData();
          refetchData();
        }, 1000);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Insufficient balance" &&
          _payload?.action === "add"
        ) {
          setInsufficientCreditsModalVisible(true);
          return;
        }
        enqueueSnackbar(err?.message || "Could not update licenses", {
          variant: "error",
        });
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  const handleAddLicense = (quantity, type) => {
    setManageLicenseModalData((prev) => ({ ...prev, visible: false }));
    setAddLicenseModalVisible({ type: "", visible: false });
    if (status === "TRIAL") return;
    if (quantity <= 0) return;
    const payload = {
      action: "add",
      quantity: Number(quantity),
    };
    setUpdateLicensePayload({ payload, type });
    if (reduxCredits?.subscription_payments) {
      updateLicense("null", type, payload);
    } else {
      setPaymentMethodModalVisible(true);
    }
  };
  const handleRemoveLicense = (quantity, type) => {
    setManageLicenseModalData((prev) => ({ ...prev, visible: false }));
    if (quantity <= 0) return;
    const payload = {
      action: "remove",
      quantity: Number(quantity),
    };
    setUpdateLicensePayload({ payload, type });
    if (
      Number(plan?.licenses?.total) - Number(quantity) >= freeNumbersCount ||
      freeNumbersCount <= 0 ||
      type !== "Plan"
    ) {
      updateLicense("null", type, payload);
    } else {
      setVnModalVisible(true);
    }
  };

  const handleRemoveNumbers = (idsList) => {
    setVnModalVisible(false);
    const payload = {
      ...updateLicensePayload.payload,
      number_ids: [...idsList],
    };
    updateLicense("null", "Plan", payload);
  };
  const handleVnModalClose = () => {
    setVnModalVisible(false);
    updateLicense("null", "Plan", updateLicensePayload);
  };
  const onPayNow = () => {
    JService.get("/api/v1/subscriptions/pay-now/")
      .then((res) => {
        if (!res?.success) {
          throw new Error(res?.message || "Something went wrong");
        }
        if (res?.url) {
          window.location.href = res.url;
        }
      })
      .catch((err) => {
        enqueueSnackbar(err?.message || "Could not process payment", {
          variant: "error",
        });
      });
  };

  const formatNumbersData = () => {
    let temp = {};
    let freeNumbers = 0;
    if (!numbers) return {};
    for (let i = 0; i < numbers?.length; i++) {
      let location = numbers[i].location?.includes("India")
        ? "India"
        : numbers[i].location;
      if (numbers[i].addons > 0) {
        if (!temp[`${location}-addon`]) {
          temp[`${location}-addon`] = {
            count: 0,
            price: numbers[i].addon_price,
          };
        }
        temp[`${location}-addon`].count += numbers[i].addons;
      }
      if (numbers[i].total > numbers[i].addons) {
        if (!temp[`${location}-free`]) {
          temp[`${location}-free`] = { count: 0, price: 0 };
        }
        freeNumbers += numbers[i].total - numbers[i].addons;
        temp[`${location}-free`].count += numbers[i].total - numbers[i].addons;
      }
    }
    setFreeNumbersCount(freeNumbers);
    setFormattedNumbersData(temp);
  };
  useEffect(() => {
    if (
      status !== "TRIAL" &&
      JSON.parse(localStorage.getItem("account-upgraded-today")) == true
    ) {
      if (Date.now() - new Date(startDate) < 86400000) {
        setUpgradeBoxVisible(true);
      } else {
        localStorage.removeItem("account-upgraded-today");
      }
    }
  }, [status, startDate]);

  useEffect(() => {
    formatNumbersData();
  }, [numbers]);

  useEffect(() => {
    const controller = new AbortController();
    if (canViewUsers) {
      if (!showLoader) setShowLoader(true);
      JService.get(
        `/api/v1/auth/retrieve-users/${getOrgURLParam()}`,
        {},
        { signal: controller.signal }
      )
        .then((res) => {
          if (!("success" in res)) return;
          if (!res?.success)
            throw new Error(res?.message || "Could not retreive users");
          setShowLoader(false);
          setUsers(res?.data);
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }

    return () => {
      controller.abort();
      setUsers([]);
    };
  }, [canViewUsers]);
  const calcPrice = () => {
    let price = Number(plan?.price);
    let discountedPrice = Number(plan?.discounted_price);
    const billingCycle = plan?.name?.includes("3")
      ? "Quarterly"
      : plan?.name?.includes?.("Yearly")
        ? "Annual"
        : "Monthly";
    if (billingCycle === "Monthly") return [price, discountedPrice];

    if (billingCycle === "Quarterly") {
      price /= 3;
      discountedPrice /= 3;
    } else if (billingCycle === "Annual") {
      price /= 12;
      discountedPrice /= 12;
    }

    return [price, Math.round(discountedPrice)];
  };

  if (status === "TRIAL") {
    return (
      <div className={styles.container}>
        <PlanModal1
          visible={upgradePlanModalVisible}
          handleClose={() => setUpgradePlanModalVisible(false)}
        />
        <section>
          <h2 className="t5 medium-font">Plans & Numbers</h2>
          <OverviewTable
            heading="Professional Plan - Trial"
            buttons={[
              <Button
                buttonText="Upgrade"
                onClick={handleUpgradeModal}
                hierarchy="green"
                disabled={false}
                key={1}
              />,
            ]}
            rows={[
              {
                data: `${plan?.licenses?.total} License${autodialData?.licenses?.total > 1 ? "s" : ""}`,
              },
            ]}
          />
          <OverviewTable
            heading="Numbers - Trial"
            rows={
              Object.keys(formattedNumbersData)?.length === 0
                ? [{ data: <p>No numbers added to account</p> }]
                : Object.keys(formattedNumbersData)?.map((elem) => {
                    return {
                      key: elem,
                      data: (
                        <p className="t7 regular-font">
                          {formattedNumbersData[elem].count}{" "}
                          {elem.split("-")[0]} number
                        </p>
                      ),
                    };
                  })
            }
          />
        </section>
        <hr />
        <section>
          <h2 className="t5 medium-font">Add-ons</h2>
          <OverviewTable
            heading="Autodial - Trial"
            rows={[
              {
                data: `${autodialData?.licenses?.total} License${autodialData?.licenses?.total > 1 ? "s" : ""}`,
              },
            ]}
          />
          {plan?.name?.includes("INR") && (
            <OverviewTable
              heading="Browser calling - Trial"
              rows={[
                {
                  data: `${browserCallingData?.licenses?.total} License${browserCallingData?.licenses?.total > 1 ? "s" : ""}`,
                },
              ]}
            />
          )}
        </section>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {endDate > Date.now() && Date.now() <= paymentDueDate && (
        <PaymentReminderModal
          planName={plan?.name}
          dueDate={endDate}
          onPayNow={onPayNow}
        />
      )}
      <AddonUsersModal
        visible={autodialUsersModalVisible}
        handleClose={() => setAutodialUsersModalVisible(false)}
        data={autodialData}
        users={users}
        handleUpdateAddonUsers={handleUpdateAddonUsers}
        addonName="AUTODIAL"
      />
      <AddonUsersModal
        visible={browserCallingUsersModalVisible}
        handleClose={() => setBrowserCallingUsersModalVisible(false)}
        data={browserCallingData}
        users={users}
        handleUpdateAddonUsers={handleUpdateAddonUsers}
        addonName="BROWSER CALLING"
      />
      <ManageLicenseModal
        handleAddLicense={handleAddLicense}
        handleRemoveLicense={handleRemoveLicense}
        visible={manageLicenseModalData?.visible}
        handleClose={handleCloseLicenseModal}
        data={manageLicenseModalData}
        renewalDate={endDate}
        planType={
          plan?.name?.includes("Professional") ? "Professional" : "Standard"
        }
      />
      <AddLicenseModal
        handleAddLicense={handleAddLicense}
        visible={addLicenseModalVisible.visible}
        handleClose={() =>
          setAddLicenseModalVisible({ type: "", visible: false })
        }
        type={addLicenseModalVisible.type}
      />
      <PaymentMethodModal
        visible={paymentMethodModalVisible}
        handleClose={() => setPaymentMethodModalVisible(false)}
        handlePayWithCredits={() => updateLicense("credits")}
        handlePayWithCard={() => updateLicense("card")}
      />
      <InsufficientCreditsModal
        visible={insufficientCreditsModalVisible}
        handleClose={() => setInsufficientCreditsModalVisible(false)}
        handlePayWithCard={() => updateLicense("card")}
        handleAddCredits={() => changeActiveTab("Credits")}
      />
      <VirtualNumberModal
        visible={vnModalVisible}
        handleClose={handleVnModalClose}
        handleRemoveNumbers={handleRemoveNumbers}
        renewalDate={endDate}
      />
      {upgradeBoxVisible && (
        <div className={styles.accountUpgradedBox}>
          <div>
            <p className="t6 semibold-font">Account upgraded successfully!</p>
            <span onClick={handleUpgradeBoxClose}>{modalCloseIcon()}</span>
          </div>
          <div className="t6 regular-font">
            <p>Thank you for choosing FreJun! 🙌</p>
            <ul>
              <li>
                Your plan includes 1 free virtual number per license. Add it to
                your account{" "}
                <button
                  type="button"
                  onClick={() => navigate("/virtual-number")}
                >
                  here
                </button>
                .
              </li>
              <li>Need help with setup? Our chat support is here for you.</li>
            </ul>
          </div>
        </div>
      )}
      {showLoader && <AnimationPage />}
      {!showLoader && (
        <>
          {endDate && status === "ACTIVE" && (
            <div className={styles.renewalInfo}>
              <p className="t7 regular-font">
                Your next invoice will be issued on{" "}
                <span className={styles.renewDate}>{formatDate(endDate)}</span>
              </p>
            </div>
          )}
          <section>
            <h2 className="t5 medium-font">Plans & Numbers</h2>
            <OverviewTable
              total={plan?.licenses?.total}
              heading={`${plan?.name?.includes("Professional") ? "Professional Plan" : "Standard Plan"}`}
              buttons={[
                //<Button key={1} variant='outlined' styleOverride={{backgroundColor:'#fff'}} buttonText='Change Plan' onClick={()=>null} hierarchy="white" disabled={false}/>,
                <Button
                  key={2}
                  variant="outlined"
                  styleOverride={{ backgroundColor: "#fff", fontSize: "14px" }}
                  buttonText="Manage License"
                  onClick={() => handleOpenLicenseModal("Plan")}
                  hierarchy="white"
                  disabled={false}
                />,
              ]}
              renewDate={endDate}
              rows={[
                {
                  data: (
                    <p>
                      <span className="medium-font">
                        {plan?.licenses?.total}
                      </span>{" "}
                      License{plan?.licenses?.total > 1 ? "s" : ""} x{" "}
                      {Number(plan?.price) > Number(plan?.discounted_price) && (
                        <span style={{ textDecoration: "line-through" }}>
                          {currency}
                          {calcPrice()[0]}
                        </span>
                      )}
                      <span className="medium-font">
                        {currency}
                        {calcPrice()[1]}
                      </span>{" "}
                      per month
                    </p>
                  ),
                },
                {
                  data: (
                    <p>
                      <span className="medium-font">
                        {plan?.licenses?.total - plan?.licenses?.free}
                      </span>{" "}
                      of{" "}
                      <span className="medium-font">
                        {plan?.licenses?.total}
                      </span>{" "}
                      License{plan?.licenses?.total > 1 ? "s" : ""} assigned
                    </p>
                  ),
                  buttonText: "Manage Users",
                  btnClick: () => navigate("/users-and-teams"),
                },
              ]}
            />

            <OverviewTable
              total={viewers?.licenses?.total}
              heading="Viewer License"
              buttons={[
                <Button
                  key={2}
                  variant="outlined"
                  styleOverride={{ backgroundColor: "#fff", fontSize: "14px" }}
                  buttonText={
                    viewers?.licenses?.total <= 0
                      ? "Add License"
                      : "Manage license"
                  }
                  onClick={() => handleOpenLicenseModal("Viewer")}
                  hierarchy="white"
                  disabled={false}
                />,
              ]}
              renewDate={endDate}
              rows={[
                {
                  data: (
                    <p>
                      <span className="medium-font">
                        {viewers?.licenses?.total}
                      </span>{" "}
                      License{viewers?.licenses?.total > 1 ? "s" : ""} x
                      {Number(viewers?.price) >
                        Number(viewers?.discounted_price) && (
                        <span style={{ textDecoration: "line-through" }}>
                          {currency}
                          {viewers?.price}
                        </span>
                      )}
                      <span className="medium-font">
                        {currency}
                        {viewers?.discounted_price}
                      </span>{" "}
                      per month
                    </p>
                  ),
                },
                {
                  data: (
                    <p>
                      <span className="medium-font">
                        {viewers?.licenses?.total - viewers?.licenses?.free}
                      </span>{" "}
                      of{" "}
                      <span className="medium-font">
                        {viewers?.licenses?.total}
                      </span>{" "}
                      License{viewers?.licenses?.total > 1 ? "s" : ""} assigned
                    </p>
                  ),
                  buttonText: "Manage Users",
                  btnClick: () => navigate("/users-and-teams"),
                },
              ]}
            />

            {/* Render OverviewTable if numbers data length is greater than 0 */}
            <OverviewTable
              heading={"Numbers"}
              total={Object.keys(formattedNumbersData)?.length}
              buttons={[
                <Button
                  key={1}
                  variant="outlined"
                  styleOverride={{ backgroundColor: "#fff", fontSize: "14px" }}
                  buttonText={"Manage number"}
                  onClick={() => navigate("/virtual-numbers")}
                  hierarchy="white"
                  disabled={false}
                />,
              ]}
              renewDate={endDate}
              rows={Object.keys(formattedNumbersData)?.map((elem) => ({
                key: elem,
                data: (
                  <p className="t7 regular-font">
                    <span className="medium-font">
                      {formattedNumbersData[elem].count}
                    </span>{" "}
                    {elem.split("-")[0]} number x{" "}
                    <span className="medium-font">
                      {currency}
                      {formattedNumbersData[elem].price}
                    </span>{" "}
                    per month{" "}
                    <span style={{ color: "#78807a", fontStyle: "normal" }}>
                      {formattedNumbersData[elem].price === 0
                        ? `(${formattedNumbersData[elem].count} included with plan)`
                        : ""}
                    </span>
                  </p>
                ),
              }))}
            />
          </section>
          <hr />
          <section>
            <h2 className="t5 medium-font">Add-ons</h2>
            {/* Render OverviewTable for Autodial */}
            <OverviewTable
              total={autodialData?.licenses?.total}
              heading={"Autodial"}
              renewDate={endDate}
              buttons={[
                <Button
                  key={1}
                  variant="outlined"
                  styleOverride={{ backgroundColor: "#fff", fontSize: "14px" }}
                  buttonText={
                    autodialData?.licenses?.total === 0
                      ? "Add license"
                      : "Manage license"
                  }
                  onClick={() => handleOpenLicenseModal("Autodial")}
                  hierarchy="white"
                  disabled={false}
                />,
              ]}
              rows={[
                {
                  data: (
                    <p>
                      <span className="medium-font">
                        {autodialData?.licenses?.total}
                      </span>{" "}
                      License{autodialData?.licenses?.total > 1 ? "s" : ""} x{" "}
                      {autodialData?.price > autodialData?.discounted_price && (
                        <span style={{ textDecoration: "line-through" }}>
                          {currency}
                          {autodialData?.price}
                        </span>
                      )}
                      <span className="medium-font">
                        {currency}
                        {autodialData?.discounted_price}
                      </span>{" "}
                      per month
                    </p>
                  ),
                },
                {
                  data: (
                    <p>
                      <span className="medium-font">
                        {autodialData?.licenses?.total -
                          autodialData?.licenses?.free}
                      </span>{" "}
                      of{" "}
                      <span className="medium-font">
                        {autodialData?.licenses?.total}
                      </span>{" "}
                      License{autodialData?.licenses?.total > 1 ? "s" : ""}{" "}
                      assigned
                    </p>
                  ),
                  buttonText: "Manage Users",
                  btnClick: () => setAutodialUsersModalVisible(true),
                },
              ]}
            />

            {/* Conditionally render Browser calling table based on plan name */}
            {plan?.name?.includes("INR") && (
              <OverviewTable
                total={browserCallingData?.licenses?.total}
                heading={"Browser calling"}
                renewDate={endDate}
                buttons={[
                  <Button
                    key={1}
                    variant="outlined"
                    styleOverride={{
                      backgroundColor: "#fff",
                      fontSize: "14px",
                    }}
                    buttonText={
                      browserCallingData?.licenses?.total === 0
                        ? "Add license"
                        : "Manage license"
                    }
                    onClick={() => handleOpenLicenseModal("browser calling")}
                    hierarchy="white"
                    disabled={false}
                  />,
                ]}
                rows={[
                  {
                    data: (
                      <p>
                        <span className="medium-font">
                          {browserCallingData?.licenses?.total}
                        </span>{" "}
                        License
                        {browserCallingData?.licenses?.total > 1
                          ? "s"
                          : ""} x{" "}
                        {browserCallingData?.price >
                          browserCallingData?.discounted_price && (
                          <span style={{ textDecoration: "line-through" }}>
                            {currency}
                            {browserCallingData?.price}
                          </span>
                        )}
                        <span className="medium-font">
                          {currency}
                          {browserCallingData?.discounted_price}
                        </span>{" "}
                        per month
                      </p>
                    ),
                  },
                  {
                    data: (
                      <p>
                        <span className="medium-font">
                          {browserCallingData?.licenses?.total -
                            browserCallingData?.licenses?.free}
                        </span>{" "}
                        of{" "}
                        <span className="medium-font">
                          {browserCallingData?.licenses?.total}
                        </span>{" "}
                        License
                        {browserCallingData?.licenses?.total > 1
                          ? "s"
                          : ""}{" "}
                        assigned
                      </p>
                    ),
                    buttonText: "Manage Users",
                    btnClick: () => setBrowserCallingUsersModalVisible(true),
                  },
                ]}
              />
            )}
          </section>
        </>
      )}
    </div>
  );
};
const OverviewTable = ({
  heading,
  buttons = [],
  renewDate = null,
  rows,
  total,
}) => {
  const { canCreateEditUsers } = useSelector(
    (state) => state?.auth?.permissions
  );
  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <span className="t6 medium-font">{heading}</span>
        {buttons.length > 0 && <div>{buttons?.map((btn) => btn)}</div>}
      </div>
      <div className={styles.body}>
        {rows?.slice(0, total > 0 ? rows.length : 1)?.map((row, index) => {
          return (
            <div key={index} className={styles.row}>
              <span className="t7 regular-font">{row.data}</span>
              {row.buttonText && canCreateEditUsers && (
                <span onClick={row.btnClick} className="b2 regular-font">
                  {row.buttonText}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OverviewTab;
