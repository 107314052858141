import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, MenuItem, Select, Grid } from "@mui/material";
import { JService } from "../../../api-service/ApiService";
import { enqueueSnackbar } from "notistack";
import { CustomChip } from "../../../design/components/customComponents";
import SendSurvey from "./SendSurvey";
import Notes from "./Notes";
import Dialpad from "./Dialpad";
import { saveCalendar } from "../../../features/calendar/calendar";
import { setDialer } from "../../../features/dialer/dialer";
import styles from "../../../design/styles";
import colors from "../../../design/colors";
import Button from "../../../design/Button";
import AnimationPage from "../../animation-page/AnimationPage";
import {
  holdCallIcon,
  muteCallIcon,
  endCallIcon,
  topNavDialerScreen3NotesEditPencilIcon,
  surveyIcon,
  topNavDialPad,
} from "../../../../utility-functions/assetLoader";
import { topNavAccountSelectedAccountTickIcon } from "../../../../utility-functions/assetLoader";

const CallDetails = ({
  onSaveCallDetails,
  browserCalls,
  contactListId,
  dialerCandidateDetails,
  allTagsRef,
  isMute,
  isHold,
  sip,
  inviteSession,
  handleSipAction,
  handleMute,
  handleHold,
  outboundVirtualNumbers,
  selectedOutboundNumber,
  orgDetails,
  updateCallStatusValues,
  setUpdateCallStatusValues,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const accountDetails = useSelector((state) => state?.account?.value);
  const globalDialerState = useSelector((state) => state?.globalDialer?.value);
  const reduxSubscription = useSelector(
    (state) => state?.account?.value?.subscription
  );
  const { canSendSurvey, canTriggerSms } = useSelector(
    (state) => state?.auth?.permissions
  );

  const [callOutcomeOptionsVisible, setCallOutcomeOptionsVisible] =
    useState(false);
  const [callReasonOptionsVisible, setCallReasonOptionsVisible] =
    useState(false);
  const [tagsOptionsVisible, setTagsOptionsVisible] = useState(false);
  const [automationTrigger, setAutomationTrigger] = useState(false);
  const [isSubmittingUpdateCallRecord, setIsSubmittingUpdateCallRecord] =
    useState(false);
  const [isNotesEditorOpen, setIsNotesEditorOpen] = useState(false);
  const [isSurveyScreenVisible, setIsSurveyScreenVisible] = useState(false);
  const [surveyDisabled, setSurveyDisabled] = useState(true);
  const [isSurveySent, setIsSurveySent] = useState(false);
  const [isDialpadVisible, setIsDialpadVisible] = useState(false);

  const handleDialpad = () => {
    setIsDialpadVisible((prevState) => !prevState);
  };

  function handleRemoveFromTags(e, value, selectedTags) {
    e.stopPropagation();
    setUpdateCallStatusValues((prevState) => {
      const temp = selectedTags?.filter((selectedTag) => selectedTag !== value);
      return { ...prevState, tags: temp };
    });
  }

  function handleRemoveCallOutcome(selected) {
    setUpdateCallStatusValues((prevState) => ({
      ...prevState,
      call_outcome: "",
    }));
  }

  function handleRemoveCallReason(selected) {
    setUpdateCallStatusValues((prevState) => ({
      ...prevState,
      call_reason: "",
    }));
  }
  const handleToggleAutomationTrigger = (e) => {
    const payload = {
      sms_trigger: e.target.checked,
      creator: accountDetails?.data?.email,
      candidate_number: dialerCandidateDetails.number,
    };
    JService.patch(`/api/v1/core/update-sms-trigger/`, JSON.stringify(payload))
      .then((res) => {
        if (!res.success) throw new Error(res?.message);
        setAutomationTrigger(res?.data?.sms_trigger);
      })
      .catch((err) => enqueueSnackbar(err?.message, { variant: "error" }));
  };

  // screen: updateCallStatus conntrols
  const handleUpdateCallStatusSaveNotes = (e) => {
    // FIXME: verify why "updateCallStatusValues?.call_id?.toString().length > 0"
    if (
      (updateCallStatusValues?.call_id?.toString().length > 0 &&
        updateCallStatusValues?.name?.trim()?.length > 0) ||
      browserCalls
    ) {
      setIsSubmittingUpdateCallRecord(true);
      //if(!call_reason || !call_reason.length || !call_outcome || !call_outcome.length) return;
      const {
        name,
        call_reason,
        call_outcome,
        tags: tag_ids,
        notes,
      } = updateCallStatusValues;
      const payload = {
        call_outcome,
        call_reason,
        tag_ids: tag_ids?.reduce(function (x, y) {
          if (y !== undefined && y !== null && x.indexOf(y) < 0) x.push(y);
          return x;
        }, []),
        notes,
      };
      if (name && name.length) payload.name = name;
      if (!browserCalls) payload.call_id = updateCallStatusValues.call_id;
      if (
        browserCalls &&
        (globalDialerState?.contact_list_id || contactListId)
      ) {
        const id = globalDialerState?.contact_list_id ?? contactListId;
        payload.contact_list = Number(id);
      }

      // ⬇️ add fe validation for null callreason/outcome [priority: ]
      // REASON: [backend validation is present: any update possible only if both call_reason.length != 0 & call_outcome.length != 0]

      let dropdownScreen3 = document.querySelector(
        ".top-nav-dialer-dropdown-screen-3"
      );
      let dropdowns = document.querySelectorAll(".dropdown");

      let url = `/api/v1/core/update-call-record/`;
      if (browserCalls) {
        const queryParam = new URLSearchParams({
          candidate_number: dialerCandidateDetails?.number,
        });
        url += "?" + queryParam;
      }
      JService.patch(url, payload)
        .then((res) => {
          console.log(res);
          if (!res?.success)
            throw new Error(res?.message || "Failed to update call record");

          // success message
          enqueueSnackbar(res?.message || "Updated call record", {
            variant: "success",
          });

          // if (globalDialerState?.isVisible)
          dispatch(
            setDialer({
              phone_number: "",
              country_code: null,
              isVisible: false,
              contact_list_id: null,
              maskNumbers: false,
            })
          );

          // reset
          //   setContactListId(null);
          //   setCallingValue("");
          setUpdateCallStatusValues({
            call_id: "",
            name: "",
            call_reason: "",
            call_outcome: "",
            notes: "",
            tags: [],
          });

          onSaveCallDetails();

          // ⬇️ find an alternative solution [priortiy: LOW]
          // alternative solution probably involves making it synchronous
          if (
            location.pathname?.includes("call-logs") ||
            location.pathname?.includes("activity-logs") ||
            location.pathname?.includes("dashboard")
          ) {
            setTimeout(() => navigate(0), 100);
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(
            error?.message,
            // ||
            //   `Error occurred while trying to contact ${callingValue}`,
            { variant: "error" }
          );

          // calendar updated so that latest calls details
          // are available   in call-logs screen (OR) for analytics(in dashboard screen for example)
          // ➡️  make refresh(prelimniary) / refetch custom-hook [priority: medium]

          const _temp = new Date();
          const _tempEnd = new Date(_temp.getTime() - 604800000); // date of the day 7 days before
          const _sendDate = {
            date_end:
              _temp.getFullYear() +
              "-" +
              (_temp.getMonth() + 1) +
              "-" +
              _temp.getDate(),
            date_start:
              _tempEnd.getFullYear() +
              "-" +
              (_tempEnd.getMonth() + 1) +
              "-" +
              _tempEnd.getDate(),
            time_start: "00:00:00",
            time_end: new Date(_temp.getTime() + 19800000)
              .toISOString()
              .split("T")[1]
              ?.slice(0, -5),
          };

          dispatch(saveCalendar(_sendDate));
        })
        .finally(() => {
          // all open dropdowns are closed/hidden
          if (dropdownScreen3) {
            dropdownScreen3.style.display = "none";
          }

          if (dropdowns instanceof NodeList) {
            dropdowns.forEach((dropdown) =>
              dropdown ? (dropdown.style.display = "none") : null
            );
          }
          dispatch(
            setDialer({
              phone_number: "",
              country_code: null,
              isVisible: false,
              contact_list_id: null,
              maskNumbers: false,
            })
          );
          setUpdateCallStatusValues({
            call_id: "",
            name: "",
            call_reason: "",
            call_outcome: "",
            notes: "",
            tags: [],
          });
          //   setCallTime({
          //     session: null,
          //     time: { sec: 0, min: 0, hr: 0 },
          //     //id: null,
          //   });
          setTimeout(() => setIsSubmittingUpdateCallRecord(false), 1000);
        });
    }
  };

  const handleUpdateCallStatusChange = (e) => {
    if (e.target.dataset.updatefieldtype === "name") {
      const _value = e.target.value;
      setUpdateCallStatusValues((prevState) => ({
        ...prevState,
        name: _value,
      }));
    }
  };

  function handleDialerScreen3NotesButtonClick(e) {
    setIsNotesEditorOpen((prevState) => !prevState);
  }

  const handleShowSurvey = () => {
    setIsSurveyScreenVisible(true);
  };

  const handleHideSurvey = () => {
    setIsSurveyScreenVisible(false);
  };

  function handleTempCallStatusValuesChange(e, fieldType) {
    setUpdateCallStatusValues((prevState) => ({
      ...prevState,
      notes: e.target.value,
    }));
  }

  const handleTagsChange = (e) => {
    let {
      target: { value },
    } = e;
    if (value instanceof Array) value = value?.reverse()[0];
    setTagsOptionsVisible(false);
    setUpdateCallStatusValues((prevState) => ({
      ...prevState,
      tags: [...prevState?.tags, value],
    }));
  };

  const renderTagsValue = (selected) => {
    if (allTagsRef.current?.length === 0)
      return <p className="t6 nc-gray-400 regular-font">No tags available</p>;
    if (
      selected?.length === 0 ||
      updateCallStatusValues?.tags?.length == 0 ||
      !updateCallStatusValues?.tags
    )
      return (
        <p
          className="t7 nc-gray-400 regular-font"
          style={{ lineHeight: "inherit" }}
        >
          + Add tags
        </p>
      );
    // at this point, selected has duplicates
    // removing duplicates before rendering
    let temp = selected?.reduce(function (x, y) {
      if (y !== undefined && y !== null && x.indexOf(y) < 0) x.push(y);
      return x;
    }, []);
    return (
      <Box
        sx={{
          maxWidth: "340px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          flexWrap: "no-wrap",
          rowGap: "4px",
        }}
      >
        {temp?.map((value) => {
          if (!value) return;
          return (
            <CustomChip
              onDelete={(e) => handleRemoveFromTags(e, value, selected)}
              label={
                allTagsRef.current?.find(
                  (atr) => atr?.id?.toString() === value?.toString()
                )?.name
              }
            />
          );
        })}
      </Box>
    );
  };

  useEffect(() => {
    if (
      isSurveySent ||
      selectedOutboundNumber?.country_code !== "+91" ||
      !dialerCandidateDetails?.number?.startsWith("+91") ||
      (browserCalls && !sip?.isCall)
    ) {
      setSurveyDisabled(true);
    } else {
      setSurveyDisabled(false);
    }
  }, [
    dialerCandidateDetails,
    selectedOutboundNumber,
    browserCalls,
    sip?.isCall,
    isSurveySent,
  ]);

  useEffect(() => {
    if (dialerCandidateDetails?.id) {
      setUpdateCallStatusValues((prev) => ({
        ...prev,
        call_id: dialerCandidateDetails.id,
      }));
    }
    if (dialerCandidateDetails?.name) {
      setUpdateCallStatusValues((prev) => ({
        ...prev,
        name: dialerCandidateDetails.name,
      }));
    }
  }, [dialerCandidateDetails]);

  if (isNotesEditorOpen && !isDialpadVisible && !isSurveyScreenVisible)
    return (
      <Notes
        notes={updateCallStatusValues.notes}
        handleChange={(e) => handleTempCallStatusValuesChange(e, "notes")}
        handleBack={handleDialerScreen3NotesButtonClick}
      />
    );
  if (isDialpadVisible && !isNotesEditorOpen && !isSurveyScreenVisible)
    return (
      <Dialpad handleBack={handleDialpad} session={inviteSession?.session} />
    );
  if (!isDialpadVisible && !isNotesEditorOpen && isSurveyScreenVisible)
    return (
      <SendSurvey
        setIsSurveySent={setIsSurveySent}
        handleBack={handleHideSurvey}
        virtualNumber={selectedOutboundNumber || outboundVirtualNumbers?.[0]}
        candidateNumber={dialerCandidateDetails}
        isCallOngoing={sip?.isCall}
        isBBCall={browserCalls}
        callId={updateCallStatusValues?.call_id}
      />
    );
  return (
    <Box>
      {/* candidate details */}
      {/* {browserCalls && (
          <div className="dialer--header">
            <div className="t7 medium-font">
              {callTime.session ? "Ongoing - " : "Completed - "}
              {`${
                callTime.time.hr > 0
                  ? ("0" + callTime.time.hr + ":").substring(-3)
                  : ""
              }${("0" + callTime.time.min + ":").substring(-3)}${(
                "0" + callTime.time.sec
              ).slice(-2)}`}
            </div>
          </div>
        )} */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid #e7e7e7",
          alignItems: "center",
          padding: "24px",
          borderRadius: "6px 6px 0px 0px",
        }}
      >
        <div
          style={{
            width: "44px",
            height: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F7FAF8",
            borderRadius: "50%",
            border: "1px solid #E1E5E2",
            fontSize: "20px",
            fontWeight: "500px",
            color: "#78807A",
            marginRight: "12px",
          }}
        >
          {updateCallStatusValues?.name?.[0]?.toUpperCase() || "-"}
        </div>
        <div>
          <input
            style={{
              width: "150px",
              border: updateCallStatusValues?.name
                ? "none"
                : `1px solid ${colors.nc_gray_300}`,
              paddingLeft: updateCallStatusValues?.name ? "" : "8px",
              fontSize: "16px",
              color: "#2F3A4D",
            }}
            placeholder="Enter name"
            className="px-16 nc-gray-900 medium-font dialer--contact_name_input"
            data-updatefieldtype="name"
            value={updateCallStatusValues?.name}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => handleUpdateCallStatusChange(e)}
          />
          {globalDialerState?.maskNumbers ? null : (
            <p
              style={{ marginTop: "2px", fontSize: "14px", color: "#2F3A4D" }}
              className="px-16 regular-font nc-gray-900"
            >
              {dialerCandidateDetails?.number?.includes("+") ? "" : "+91"}{" "}
              {dialerCandidateDetails?.number}
            </p>
          )}
        </div>
      </Box>

      {/* tags section */}
      <Box
        sx={{
          padding: "16px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "68px", // from design file
        }}
      >
        <Select
          open={allTagsRef.current?.length === 0 ? false : tagsOptionsVisible}
          close={!tagsOptionsVisible}
          onOpen={() =>
            allTagsRef.current?.length === 0
              ? null
              : tagsOptionsVisible
                ? null
                : setTagsOptionsVisible(true)
          }
          onClose={() =>
            !tagsOptionsVisible ? null : setTagsOptionsVisible(false)
          }
          IconComponent={null}
          defaultOpen={false}
          multiple={true}
          multiline={true}
          autoWidth={true}
          autoFocus={false}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: 40,
          }}
          placeholder="No tags selected"
          value={updateCallStatusValues?.tags}
          onChange={handleTagsChange}
          renderValue={renderTagsValue}
          sx={{ ...localStyles.mui_select, padding: "0 10px" }}
          inputProps={{ "aria-label": "Without label" }}
          displayEmpty
        >
          {allTagsRef.current?.map((tag, index) => (
            <MenuItem
              sx={{
                "&.Mui-selected": {
                  backgroundColor: `${colors.ic_green_100} !important`,
                },
              }}
              key={index}
              value={tag?.id}
            >
              {tag?.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* call reason: single-select */}
      <Box
        sx={{
          // marginBottom: "16px",
          paddingLeft: "16px",
          paddingRight: "16px",
          height: "36px",
          borderRadius: "4px",
          outline: "none",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label className="medium-font t7 nc-gray-900">Reason</label>
        <Select
          open={callReasonOptionsVisible}
          close={!callReasonOptionsVisible}
          onOpen={() =>
            callReasonOptionsVisible ? null : setCallReasonOptionsVisible(true)
          }
          onClose={() =>
            !callReasonOptionsVisible
              ? null
              : setCallReasonOptionsVisible(false)
          }
          IconComponent={null}
          multiple={false}
          multiline={false}
          autoFocus={false}
          placeholder="No members selected"
          value={updateCallStatusValues?.call_reason || ""}
          onChange={(e) => {
            let {
              target: { value },
            } = e;
            // if(value instanceof Array) value = value?.reverse()[0] // will not happen
            setUpdateCallStatusValues((prevState) => ({
              ...prevState,
              call_reason: value,
            }));
          }}
          renderValue={(selected) => {
            if (selected?.length === 0)
              return (
                <p
                  className="t7 nc-gray-400 regular-font"
                  style={{ textAlign: "right" }}
                >
                  + Add reason
                </p>
              );
            return (
              <CustomChip
                onDelete={(e) => {
                  e.stopPropagation();
                  handleRemoveCallReason(selected);
                }}
                label={selected}
              />
            );
          }}
          sx={localStyles.select}
          inputProps={{ "aria-label": "Without label" }}
          displayEmpty
        >
          {orgDetails?.call_reasons?.map((oco, index) => (
            <MenuItem
              key={index + oco}
              value={oco}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: `${colors.ic_green_100} !important`,
                },
                "&:hover": {
                  backgroundColor: `${colors.ic_green_100} !important`,
                },
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <span style={{ flexGrow: 1 }}>{oco}</span>
                {updateCallStatusValues?.call_reason === oco &&
                  topNavAccountSelectedAccountTickIcon()}
              </div>
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* call outcome: single-select*/}
      <Box
        sx={{
          marginBottom: "8px",
          height: "36px",
          paddingLeft: "16px",
          paddingRight: "16px",
          borderRadius: "4px",
          outline: "none",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label className="medium-font t7 nc-gray-900">Outcome</label>
        <Select
          open={callOutcomeOptionsVisible}
          close={!callOutcomeOptionsVisible}
          onOpen={() =>
            callOutcomeOptionsVisible
              ? null
              : setCallOutcomeOptionsVisible(true)
          }
          onClose={() =>
            !callOutcomeOptionsVisible
              ? null
              : setCallOutcomeOptionsVisible(false)
          }
          IconComponent={null}
          multiple={false}
          multiline={false}
          autoFocus={false}
          placeholder="No members selected"
          value={updateCallStatusValues?.call_outcome || ""}
          onChange={(e) => {
            let {
              target: { value },
            } = e;
            setUpdateCallStatusValues((prevState) => ({
              ...prevState,
              call_outcome: value,
            }));
          }}
          renderValue={(selected) => {
            if (selected?.length === 0)
              return (
                <p
                  className="t7 nc-gray-400 regular-font"
                  style={{ textAlign: "right" }}
                >
                  + Add outcome
                </p>
              );
            return (
              <CustomChip
                onDelete={(e) => {
                  e.stopPropagation();
                  handleRemoveCallOutcome(selected);
                }}
                label={selected}
              />
            );
          }}
          inputProps={{ "aria-label": "Without label" }}
          displayEmpty
          sx={localStyles.select}
        >
          {orgDetails?.call_outcomes?.map((oco, index) => (
            <MenuItem
              key={index + oco}
              value={oco}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: `${colors.ic_green_100} !important`,
                },
                "&:hover": {
                  backgroundColor: `${colors.ic_green_100} !important`,
                },
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <span style={{ flexGrow: 1 }}>{oco}</span>
                {updateCallStatusValues?.call_outcome === oco &&
                  topNavAccountSelectedAccountTickIcon()}
              </div>
            </MenuItem>
          ))}
        </Select>
      </Box>
      {canTriggerSms && (
        <div className="trigger-container">
          <span className="t7 regular-font">Automation trigger</span>
          <div className="switch custom-switch">
            <label>
              <input
                type="checkbox"
                checked={automationTrigger}
                onChange={handleToggleAutomationTrigger}
                style={{ cursor: "pointer", marginTop: "0" }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      )}
      <div
        style={{
          borderTop: "1px solid #e1e5e2",
          paddingTop: "16px",
          backgroundColor: colors.nc_gray_100,
        }}
      >
        {!browserCalls && (
          <div className="flex-container">
            <Button
              onClick={handleDialerScreen3NotesButtonClick}
              buttonText="Add notes"
              icon={{
                startIcon: topNavDialerScreen3NotesEditPencilIcon(),
                endIcon: null,
              }}
              hierarchy="white"
              variant="contained"
              isLoading={false}
              disabled={false}
              styleOverride={{
                width: "100%",
                border: `1px solid ${colors.nc_gray_300}`,
                padding: "6px 16px",
                marginBottom: "10px",
                backgroundColor: colors.ic_white,
                "&:hover": {
                  border: "1px solid #e1e5e2",
                },
                ...styles.t7,
              }}
              spanStyles={{ flexGrow: 1 }}
            />
            {canSendSurvey && (
              <Button
                onClick={handleShowSurvey}
                buttonText="Send survey"
                icon={{
                  startIcon: surveyIcon(
                    surveyDisabled || isSurveySent
                      ? colors.nc_gray_300
                      : colors.nc_gray_900
                  ),
                  endIcon: null,
                }}
                hierarchy="white"
                variant="contained"
                isLoading={false}
                disabled={surveyDisabled || isSurveySent}
                styleOverride={{
                  width: "100%",
                  border: `1px solid ${colors.nc_gray_300}`,
                  padding: "6px 16px",
                  marginBottom: "10px",
                  backgroundColor: colors.ic_white,
                  "&:hover": {
                    border: "1px solid #e1e5e2",
                  },
                  "&.Mui-disabled": {
                    border: `1px solid ${colors.nc_gray_300}`,
                  },
                  ...styles.t7,
                }}
              />
            )}
          </div>
        )}
        {browserCalls && (
          <Grid sx={{ backgroundColor: colors.nc_gray_100 }}>
            <Grid container style={localStyles.callOptionsRow}>
              <Button
                tooltipMessage={sip?.isCall ? "Mute" : ""}
                onClick={handleMute}
                buttonText={muteCallIcon(
                  sip?.isCall
                    ? isMute
                      ? colors.ic_green_300
                      : colors.nc_gray_900
                    : colors.nc_gray_400
                )}
                icon={{
                  startIcon: null,
                  endIcon: null,
                }}
                hierarchy="white"
                variant="contained"
                isLoading={false}
                disabled={!sip?.isCall}
                styleOverride={{
                  width: "86.66px",
                  height: "36px",
                  border: `1px solid ${
                    isMute ? colors.ic_green_300 : colors.nc_gray_300
                  }`,
                  backgroundColor: isMute
                    ? colors.ic_green_100
                    : colors.ic_white,
                  "&:hover": {
                    border: sip?.isCall
                      ? !isMute
                        ? "1px solid #e1e5e2"
                        : "1px solid #34cb65"
                      : "",
                  },
                  "&.Mui-disabled": {
                    border: `1px solid ${colors.nc_gray_300}`,
                  },
                }}
              />
              <Button
                tooltipMessage={sip?.isCall ? "Hold" : ""}
                onClick={handleHold}
                buttonText={holdCallIcon(
                  sip?.isCall
                    ? isHold
                      ? colors.ic_green_300
                      : colors.nc_gray_900
                    : colors.nc_gray_400
                )}
                icon={{
                  startIcon: null,
                  endIcon: null,
                }}
                hierarchy="white"
                variant="contained"
                isLoading={false}
                disabled={!sip?.isCall}
                styleOverride={{
                  width: "86.66px",
                  height: "36px",
                  border: `1px solid ${colors.nc_gray_300}`,
                  border: `1px solid ${
                    isHold ? colors.ic_green_300 : colors.nc_gray_300
                  }`,
                  backgroundColor: isHold
                    ? colors.ic_green_100
                    : colors.ic_white,
                  "&:hover": {
                    border: sip?.isCall
                      ? !isHold
                        ? "1px solid #e1e5e2"
                        : "1px solid #34cb65"
                      : "",
                  },
                  "&.Mui-disabled": {
                    border: `1px solid ${colors.nc_gray_300}`,
                  },
                }}
              />
              <Button
                tooltipMessage={sip?.isCall ? "Dialpad" : ""}
                onClick={handleDialpad}
                buttonText={topNavDialPad(
                  sip?.isCall ? colors.nc_gray_900 : colors.nc_gray_400
                )}
                icon={{
                  startIcon: null,
                  endIcon: null,
                }}
                hierarchy="white"
                variant="contained"
                isLoading={false}
                disabled={!sip?.isCall}
                styleOverride={{
                  width: "86.66px",
                  height: "36px",
                  border: `1px solid ${colors.nc_gray_300}`,
                  backgroundColor: colors.ic_white,
                  "&:hover": {
                    border: sip?.isCall ? "1px solid #e1e5e2" : "",
                  },
                  "&.Mui-disabled": {
                    border: `1px solid ${colors.nc_gray_300}`,
                  },
                }}
              />
            </Grid>
            <Grid container style={localStyles.callOptionsRow}>
              <Button
                tooltipMessage={"Add notes"}
                onClick={handleDialerScreen3NotesButtonClick}
                buttonText={topNavDialerScreen3NotesEditPencilIcon()}
                icon={{}}
                hierarchy="white"
                variant="contained"
                isLoading={false}
                disabled={false}
                styleOverride={{
                  width: "86.66px",
                  height: "36px",
                  border: `1px solid ${colors.nc_gray_300}`,
                  backgroundColor: colors.ic_white,
                  "&:hover": {
                    border: "1px solid #e1e5e2",
                  },
                }}
              />
              {canSendSurvey && (
                <Button
                  tooltipMessage={sip?.isCall ? "Send survey" : ""}
                  onClick={handleShowSurvey}
                  buttonText={surveyIcon(
                    surveyDisabled || isSurveySent
                      ? colors.nc_gray_400
                      : colors.nc_gray_900
                  )}
                  icon={{}}
                  hierarchy="white"
                  variant="contained"
                  isLoading={false}
                  disabled={surveyDisabled || isSurveySent}
                  styleOverride={{
                    width: "86.66px",
                    height: "36px",
                    border: `1px solid ${colors.nc_gray_300}`,
                    backgroundColor: colors.ic_white,
                    "&:hover": {
                      border: "1px solid #e1e5e2",
                    },
                    "&.Mui-disabled": {
                      border: `1px solid ${colors.nc_gray_300}`,
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>
        )}
        {/* save button container */}
        <Box
          sx={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingBottom: "16px",
            paddingTop: "16px",
            borderTop: `1px solid ${colors.nc_gray_300}`,
          }}
        >
          {" "}
          {!browserCalls || !sip?.isCall ? (
            <Button
              buttonText="Save"
              hierarchy="green"
              variant="contained"
              isLoading={isSubmittingUpdateCallRecord}
              disabled={false}
              onClick={handleUpdateCallStatusSaveNotes}
              icon={{ startIcon: null, endIcon: null }}
              styleOverride={{
                width: "100%",
                marginBottom: "6px",
                height: "40px",
                ...styles.b1,
                ...styles.medium_font,
                color: colors.ic_white,
              }}
            />
          ) : (
            <Button
              buttonText={endCallIcon()}
              hierarchy="red"
              variant="contained"
              isLoading={isSubmittingUpdateCallRecord}
              disabled={false}
              // end call for established sessions is done via session.bye()
              onClick={() => handleSipAction("bye")}
              icon={{ startIcon: null, endIcon: null }}
              styleOverride={{
                width: "100%",
                marginBottom: "6px",
                height: "40px",
                ...styles.b1,
                ...styles.medium_font,
                color: colors.ic_white,
              }}
            />
          )}
        </Box>
      </div>

      {/* show loader on submitting */}
      {isSubmittingUpdateCallRecord && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "9",
            background: "#ffffffdd",
          }}
        >
          <AnimationPage />
        </div>
      )}
    </Box>
  );
};

export default CallDetails;

const localStyles = {
  mui_select: {
    "&.MuiMenuItem-root.Mui-selected": {
      border: "1px solid red",
    },
    alignSelf: "flex-start",
    minWidth: 200,
    width: "100%",
    maxWidth: "100%",
    height: "40px",
    outline: "none",
    borderRadius: "4px",
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    ...styles.t6,
    ...styles.regular_font,
    color: colors.nc_gray_900,
    "&:before": {
      outline: "none",
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #34CB65",
      boxShadow: "0px 0px 0px 4px #F0FCF4",
    },
  },
  select: {
    alignSelf: "flex-start",
    width: "200px",
    outline: "none",
    borderRadius: "4px",
    padding: 0,
    marginBottom: "24px",
    paddingRight: 0,
    ...styles.t6,
    ...styles.regular_font,
    ...colors.nc_gray_900,
    "&:before": {
      outline: "none",
      border: "none",
    },
    "&:focus .MuiOutlinedInput-input": {
      border: "none",
      outline: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px 8px 12px",
      paddingRight: "0px !important",
      border: "none",
      outline: "none",
      textAlign: "right",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      textAlign: "right",
    },
  },
  callOptionsRow: {
    justifyContent: "flex-start",
    margin: "0px 16px 10px",
    width: "calc(100% - 32px)",
    gap: "10px",
  },
};
