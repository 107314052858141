import { useEffect, useRef, useState } from 'react'
import styles from './ActivityLogs.module.css'
import CallLogs from '../call-logs/CallLogs'
import SmsLogs from './Sms/SmsLogs'
import { useSelector } from 'react-redux'
import { JService } from '../api-service/ApiService'
import { useNavigate, useParams } from 'react-router-dom'
import MessageHistoryModal from './Sms/MessageHistoryModal'
import { getOrgURLParam, getPermissionId, initiateCall } from '../../utility-functions'
import { enqueueSnackbar } from 'notistack'

const ActivityLogs=({tab='Calls'})=>{
    const API_BASE_URL=process.env.REACT_APP_BETA

    const params = useParams()
    const navigate=useNavigate()

    const reduxVn=useSelector((state)=>state?.virtualNumber?.value);
    const reduxAccount=useSelector((state)=>state?.account?.value)
    const reduxSubscription=useSelector(state=>state?.account?.value?.subscription);
    const {canViewCallLogs,
          canViewSmsLogs,
          canViewUsers, 
          canViewTeams} = useSelector(state => state?.auth?.permissions);

    const [activeTab,setActiveTab]=useState(tab)
    const [users, setUsers] =useState([])
    const [teams, setTeams]=useState([])
    const [allTags, setAllTags]=useState([])
    const [tagDetailsSet, setTagDetailsSet] = useState(false);
    const [messageHistoryModal, setMessageHistoryModal]=useState({visible:(!params.id && params.id!=0)?false:true, id:params.id, candidateDetails:null, viewOnly:'unknown', viewedMsgId:(!params.id && params.id!=0)?null:params.id})
    const [messagingEnabled, setMessagingEnabled]=useState(false)
    const [tabs, setTabs] = useState([]);
    const [callRecordingVisible, setCallRecordingVisible] = useState({visible: false, id: null});
    
    const allTagsRef = useRef([]);


    const handleOpenConversation=(e,params,source)=>{
      //when message icon is clicked either in call logs or sms logs
      if(source==='call-logs' || source==='sms-logs'){
        const candidateDetails={}
        const primaryVirtualNumber=reduxAccount?.data?.profile?.default_sms_number
        const data={
          "user_number": primaryVirtualNumber,
          "creator": reduxAccount?.data?.email
        }
        if(source==='call-logs'){
          candidateDetails['name']=params?.row?.logsContact?.candidateName;
          candidateDetails['phone_number']=params?.row?.logsContact?.candidateNumber;
          data['contact_number']= params?.row?.logsContact?.candidateNumber;
        }
        else if(source==='sms-logs'){
          candidateDetails['name']=params?.row?.contact_name;
          candidateDetails['phone_number']=params?.row?.contact_number;
          data['contact_number']=params?.row?.contact_number;
        }
        setMessageHistoryModal({visible:true, id:null, payload:data, viewOnly:false, viewedMsgId:null, candidateDetails})
      }
    }

    const handleCloseInteractionModal=()=>{
      setMessageHistoryModal({visible:false, id:null, payload:null, candidateDetails:null, viewOnly:null, viewedMsgId:null})
    }

    const fetchData=()=>{
      const permissionIdParam = `?permission_id=${getPermissionId(null, activeTab == 'Calls' ? 'call logs' : 'sms logs')}`
       //tags
      JService.get(API_BASE_URL + `/api/v1/auth/retrieve-tags/${getOrgURLParam()}`)
        .then((res) => {
          if (!res?.success)
            throw new Error(res?.message || "Could not retreive tags");
          setAllTags(res?.data);
          setTagDetailsSet(true);
          allTagsRef.current = res?.data;
        })
        .catch((err) =>{
          enqueueSnackbar(err?.message, {variant:'error'})
        }
        );

    // retrieve-users for filter
      if(canViewUsers){
        JService.get(
          API_BASE_URL +
            `/api/v1/auth/retrieve-users/${getOrgURLParam()}/${permissionIdParam}`
        )
          .then((res) => {
            if (!res.success)
              throw new Error(res?.message || "Could not retreive users");
            setUsers(res?.data || []);   
          })
          .catch((err) =>{
            enqueueSnackbar(err?.message, {variant:'error'})
          }
          );
        }

      if(canViewTeams){
        JService.get(API_BASE_URL + `/api/v1/auth/teams/${permissionIdParam}`)
          .then((res) => {
            if (res?.status === 500)
              throw new Error(
                "Server error please try again or if issue persists contact FreJun"
              );
            if (!res?.success)
              throw new Error(res?.message || `Could not retrieve teams`);
            setTeams(res?.data || [])
            
          })
          .catch((err) => console.warn(`Could not retrieve teams`, err));
        }
      }

  useEffect(() => {
    let tabs = [];
    if(canViewCallLogs) tabs.push('Calls');
    if(canViewSmsLogs) tabs.push('Messages');

    setTabs(tabs);
  }, [canViewCallLogs, canViewSmsLogs])
      
  // api-fetch
  useEffect(() => {
   fetchData()
  }, [activeTab, canViewUsers, canViewTeams]);

  useEffect(()=>{
    if(reduxVn && Object.keys(reduxVn)?.length){
      const {
        outbound_virtual_numbers,
        inbound_virtual_numbers,
        team_virtual_numbers,
      } = reduxVn;
    
      const combinedInbound = [
        ...(inbound_virtual_numbers || []),
        ...(team_virtual_numbers || []),
      ];
    
      const intInbound = combinedInbound.filter((vn) => vn.country_code !== '+91');
      const smsEnabledNumbers = intInbound.some((vn) =>
        outbound_virtual_numbers?.find((elem) => elem.id === vn.id)
      );
    
      setMessagingEnabled(smsEnabledNumbers)
    }
  },[reduxVn]);

   // NOTE: if there is an issue with tags being unavailable in call-logs screen when a user has multiple accounts(orgs), move orgFetch from call-logs to fetchData in ActivityLogs and call fetchData only if tagDetails transitions from true -> false. Optionally move entire account switching(reduxAccount in CallLogs) to ActivityLogs.

    return (
    <div
    className="screen-container"
    style={{
      paddingTop: "24px",
      paddingLeft:'0',
      height:
        document.documentElement.scrollHeight - 56 >= window.innerHeight
          ? "fit-content"
          : window.innerHeight,
    }}
  >
  {!reduxSubscription?.plan?.name?.includes('USD') && <CallLogs callRecordingVisible={callRecordingVisible} setCallRecordingVisible={setCallRecordingVisible} displayTitle={true} initiateCall={initiateCall} messagingEnabled={false} setMessageHistoryModal={null} handleOpenConversation={null} users={users} allTags={allTags} teams={teams} fetchData={fetchData} tagDetailsSet={tagDetailsSet} setTagDetailsSet={setTagDetailsSet} allTagsRef={allTagsRef}/>}
  {reduxSubscription?.plan?.name?.includes('USD') && (
    <>
  {callRecordingVisible?.visible ? null : (
    <div className={styles.tabContainer}>
      <hr />
      {
          [tabs.map((tab,index)=>{
              return (
                  <p
                      className={`${styles.tabLabel} ${
                        tab === activeTab ? styles.selectedTabLabel:''
                      } b2 regular-font`}
                      key={index}
                      onClick={() =>{navigate(`/activity-logs/${tab.toLowerCase()}`); setActiveTab(tab)}}
                    >
                      {tab}
                    </p>
              )
          })]
      }
      <hr />
   </div>
  )}
   <>
   {activeTab==="Calls" && <CallLogs callRecordingVisible={callRecordingVisible} setCallRecordingVisible={setCallRecordingVisible} displayTitle={false}  initiateCall={initiateCall} messagingEnabled={messagingEnabled} setMessageHistoryModal={setMessageHistoryModal} handleOpenConversation={handleOpenConversation} users={users} allTags={allTags} teams={teams} fetchData={fetchData} tagDetailsSet={tagDetailsSet} setTagDetailsSet={setTagDetailsSet} allTagsRef={allTagsRef}/>}
   {activeTab==='Messages' && <SmsLogs initiateCall={initiateCall}  messagingEnabled={messagingEnabled} setMessageHistoryModal={setMessageHistoryModal} handleOpenConversation={handleOpenConversation} id={params?.id} users={users} allTags={allTags} teams={teams} fetchData={fetchData} tagDetailsSet={tagDetailsSet} setTagDetailsSet={setTagDetailsSet}/>}
   <MessageHistoryModal payload={messageHistoryModal.payload} visible={messageHistoryModal.visible} id={messageHistoryModal.id} candidateDetails={messageHistoryModal.candidateDetails} viewOnly={messageHistoryModal.viewOnly} viewedMsgId={messageHistoryModal.viewedMsgId} onClose={handleCloseInteractionModal}/>
   </>
   </>
  )
}
  </div>
  )
}

export default ActivityLogs