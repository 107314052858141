import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from "react-redux";

import "./components/design/legacyCSS.css";
import { Helmet } from "react-helmet";
import { useHubspotChat } from "./hubspot";
import hubspotChatOpen from "./assets/hubspot-chat-open.svg";
import hubspotChatClose from "./assets/hubspot-chat-close.svg";
import { SnackbarProvider } from "notistack";

//NOTE: Component imports
import Navbar from "./components/shared/navbar/Navbar";
import Login from "./components/login/Login";
import LoginScreen2 from "./components/login/LoginScreen2";
import Signup from "./components/signup/Signup";
import Invite from "./components/invite-flow/Invite";
import PlatformRules from "./components/platform-rules/PlatformRules";
import ActivityLogs from "./components/ActivityLogs/ActivityLogs";
import CustomSnackbar from "./components/design/components/CustomSnackbar.jsx";
// import AnimationPage from "./components/shared/animation-page/AnimationPage.js";
import SignupScreen2 from "./components/signup/SignupScreen2";
import SignupScreen4 from "./components/signup/SignupScreen4";
import SignupScreen6 from "./components/signup/SignupScreen6";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import PageNotFound from "./components/page-not-found/PageNotFound";
import Dashboard from "./components/dashboard/dashboard";
import Settings from "./components/settings/Settings";
import Denied from "./components/denied/Denied";
import SharedCallRecording from "./components/shared-call-recording/SharedCallRecording";
import ForgotPasswordVerify from "./components/forgot-password/ForgotPasswordVerify";
import ForgotPasswordReset from "./components/forgot-password/ForgotPasswordReset";
import ContactLists from "./components/contact-lists/ContactLists";
import TeamView from "./components/users-and-teams/teams/TeamView";
import ContactListsView from "./components/contact-lists-view/ContactListsView";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute.js";
// import InteractionHistory from "./components/contact-lists-view/InteractionHistory/Activity";

// import Status from "./components/shared/status/Status";
// import VoiceBroadcast from "./components/voice-broadcast/VoiceBroadcast";
// import VoiceBroadcastView from "./components/voice-broadcast-view/VoiceBroadcastView";
// import AddAccount from "./components/add-account/AddAccount";
import UsersAndTeam from "./components/users-and-teams/UsersAndTeams";
import VirtualNumber from "./components/virtual-numbers/VirtualNumber";
import VirtualNumberView from "./components/virtual-number-view/VirtualNumberView";
import Autodial from "./components/autodial/Autodial";
import AutodialView from "./components/autodial-view/AutodialView";
import Survey from "./components/survey/Survey.jsx";
import SurveyView from "./components/survey/SurveyView.jsx";
import MessageTemplates from "./components/message-templates/MessageTemplates";

import Oauth from "./components/settings/Oauth";
import SignupScreen7 from "./components/signup/SignupScreen7";
import Billing from "./components/billing/Billing";
import AccountSuspended from "./components/AccountSuspended/AccountSuspended.jsx";
import CallPricing from "./components/call-pricing/CallPricing";
//import CallLogs from "./components/newcalls/NewCallLogs";
import CallRecordingNew from "./components/call-recording-new/CallRecordingNew.jsx";
import { useEffect } from "react";
import {
  setPermissionsList,
  setUserRoles,
} from "./components/features/permission/permission.js";
import { fetchData } from "./utility-functions/index.js";
import {
  storePermissions,
  storeValidPaths,
} from "./components/features/permission/auth.js";
import { safeLogout } from "./components/api-service/ApiService.js";
import PermissionDenied from "./components/denied/PermissionDenied.jsx";
import { setNavigate } from "./components/features/user/user.js";
import FirstLogin from "./components/FirstLogin.jsx";

const SeoFreindlyLogin = () => {
  return (
    <>
      <Helmet>
        <title>Login - FreJun</title>
        <meta
          name="description"
          content="Login to your FreJun account to make outbound calls and access your call recordings with call metrics."
        />
        <meta name="keywords" content="cloud telephony" />
      </Helmet>
      <Login />
    </>
  );
};

export default function App() {
  const dispatch = useDispatch();

  const reduxSubscription = useSelector(
    (state) => state?.account?.value?.subscription
  );
  const accessList = useSelector(
    (state) => state?.account?.value?.data?.access_list
  );
  const userRoles = useSelector((state) => state?.auth?.userRoles);
  const permissions = useSelector((state) => state?.auth?.permissions);
  const isLoggedIn = useSelector((state) => state?.user?.value?.isLoggedIn);
  const loginSource = useSelector((state) => state?.user?.value?.source);

  const checkParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const fromSupport = searchParams.get("support");
    const addVN = searchParams.get("add-vn");
    // open widget
    if (fromSupport || addVN) {
      console.log("opening widget");
      handleToggleWidget();
    }
  };
  const { hasLoaded, activeConversation, handleToggleWidget, isWidgetOpen } =
    useHubspotChat("5132602", checkParams);

  useEffect(() => {
    if (!isLoggedIn) return;
    const setRoles = (roles) => dispatch(setUserRoles(roles));
    fetchData("/api/v1/auth/retrieve-user-roles/", setRoles, "user roles");

    const setPermissions = (permissions) =>
      dispatch(setPermissionsList(permissions));
    fetchData(
      "/api/v1/auth/retrieve-permissions/",
      setPermissions,
      "permissions"
    );
  }, [isLoggedIn]);

  useEffect(() => {
    console.log(
      "userRoles",
      userRoles,
      userRoles.length,
      permissions?.canAccessWebApp
    );
    if (
      userRoles &&
      userRoles.length &&
      permissions &&
      Object.keys(permissions).length &&
      !permissions.canAccessWebApp
    ) {
      safeLogout();
      return;
    }
    if (userRoles && userRoles.length) {
      storePermissions();
      storeValidPaths();
    }
    if (loginSource === "signup") {
      dispatch(setNavigate(true));
    }
  }, [userRoles, accessList, loginSource]);

  return (
    <>
      <BrowserRouter>
        {/* <Suspense fallback={<AnimationPage/>}> */}
        <SnackbarProvider
          autoHideDuration={6000}
          maxSnack={1}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          Components={{
            success: CustomSnackbar,
            error: CustomSnackbar,
          }}
        />

        {/* page meta and navbar */}
        <Navbar />
        <FirstLogin/>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<SeoFreindlyLogin />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/login/verification" element={<LoginScreen2 />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password/verification"
            element={<ForgotPasswordVerify />}
          />
          <Route
            path="/forgot-password/reset"
            element={<ForgotPasswordReset />}
          />
          <Route path="/signup/verification" element={<SignupScreen2 />} />
          <Route path="/signup/company-details" element={<SignupScreen4 />} />
          <Route
            path="/signup/trial-number"
            element={<SignupScreen6 handleToggleWidget={handleToggleWidget} />}
          />
          <Route
            path="/card-details"
            element={
              <SignupScreen7
                isWidgetOpen={isWidgetOpen}
                handleToggleWidget={handleToggleWidget}
              />
            }
          />
          <Route path="/account-suspended" element={<AccountSuspended />} />

          {/* TODO: shouldn't the check be reduxSubscription?.status === 'INACTIVE'? */}
          {isProductAccessible(reduxSubscription) && (
            <>
              <Route element={<ProtectedRoute action="dashboard" />}>
                <Route path="/dashboard" element={<Dashboard />} />
              </Route>
              <Route
                element={
                  <ProtectedRoute category={["users and roles", "teams"]} />
                }
              >
                <Route path="/users-and-teams" element={<UsersAndTeam />} />
                <Route path="/teams/:id" element={<TeamView />} />
              </Route>
              <Route
                element={
                  <ProtectedRoute category="billing and subscriptions" />
                }
              >
                <Route path="/billing" element={<Billing />} />
              </Route>
              <Route element={<ProtectedRoute category="contacts" />}>
                <Route path="/contact-lists" element={<ContactLists />} />
                <Route
                  path="/contact-lists/:id"
                  element={<ContactListsView />}
                />
              </Route>
              <Route element={<ProtectedRoute action="call logs" />}>
                <Route
                  path="/call-logs"
                  element={<ActivityLogs tab="Calls" />}
                />
                <Route
                  path="/activity-logs/calls"
                  element={<ActivityLogs tab="Calls" />}
                />
              </Route>
              <Route element={<ProtectedRoute action="sms logs" />}>
                <Route
                  path="/activity-logs/messages"
                  element={<ActivityLogs tab="Messages" />}
                />
                <Route
                  path="/messages/:id"
                  element={<ActivityLogs tab="Messages" />}
                />
              </Route>
              <Route element={<ProtectedRoute action="message templates" />}>
                <Route path="/messages" element={<MessageTemplates />} />
              </Route>
              <Route element={<ProtectedRoute category="numbers" />}>
                <Route
                  path="/virtual-numbers"
                  element={
                    <VirtualNumber
                      isWidgetOpen={isWidgetOpen}
                      handleToggleWidget={handleToggleWidget}
                    />
                  }
                />
                {/* <Route path="/virtual-numbers/add-number" element={<AddVirtualNumber/>}/> */}
                <Route
                  path="/virtual-numbers/:number"
                  element={<VirtualNumberView />}
                />
              </Route>
              {accessList?.includes("AUTODIAL") && (
                <>
                  <Route path="/autodial" element={<Autodial />} />
                  <Route path="/autodial/:id" element={<AutodialView />} />
                </>
              )}
              {accessList?.includes("IVR SURVEY") && (
                <Route element={<ProtectedRoute category="surveys" />}>
                  <Route path="/survey" element={<Survey />} />
                  <Route path="/survey/:id" element={<SurveyView />} />
                </Route>
              )}
              <Route path="/oauth/authorize" element={<Oauth />} />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/interviews/shared-interview/:id"
                element={
                  <SharedCallRecording 
                    isWidgetOpen={isWidgetOpen}
                    handleToggleWidget={handleToggleWidget}
                  />
                }
              />
              <Route
                path="/call-recording/:id"
                element={
                  <CallRecordingNew
                    isWidgetOpen={isWidgetOpen}
                    handleToggleWidget={handleToggleWidget}
                  />
                }
              />
              <Route path="/deny" element={<Denied />} />
              <Route path="/platform-rules" element={<PlatformRules />} />
            </>
          )}
          <Route path="/permission-denied" element={<PermissionDenied />} />
          <Route path="/call-pricing-usd" element={<CallPricing />} />
          <Route path="/call-pricing-inr" element={<CallPricing />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {/* </Suspense> */}
      </BrowserRouter>

      <ChatSupport
        isWidgetOpen={isWidgetOpen}
        handleToggleWidget={handleToggleWidget}
      />
    </>
  );
}

const isProductAccessible = (subscription) => {
  const isAccountSuspended = ['INACTIVE', 'PAUSED'].includes(subscription?.status);
  const isTrialPreActivation = !isCurrentDate7DaysLaterThanTrialEnd(subscription?.end_date);
  return (!isAccountSuspended || isAccountSuspended && isTrialPreActivation)
}

const isCurrentDate7DaysLaterThanTrialEnd = (date) => {
  const endDate = new Date(date);
  const today = new Date();
  const timeDifference = today.getTime() - endDate.getTime();
  const daysSince = Math.ceil(timeDifference / (1000 * 3600 * 24));
  if (daysSince >= 7) return true;

  return false;
};

function ChatSupport({ isWidgetOpen, handleToggleWidget }) {
  return (
    <div style={localStyles.chatSupportContainer}>
      <button
        style={localStyles.chatSupportButton}
        onClick={handleToggleWidget}
      >
        <img
          src={isWidgetOpen ? hubspotChatClose : hubspotChatOpen}
          alt="open chat"
        />
      </button>
    </div>
  );
}

const localStyles = {
  chatSupportContainer: {
    position: "fixed",
    right: "16px",
    bottom: "16px",
    width: "70px",
    height: "70px",
    zIndex: "10",
  },
  chatSupportButton: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    backgroundColor: "#00a4bd",
    display: "block",
  },
};

// removed paths
// {/* <Route path="/add-account" element={<AddAccount />} /> */}
// {/* <Route path="/call-logs/:id" element={<CallRecording />} /> */}
// {/* <Route path="/voice-broadcast" element={<VoiceBroadcast />} /> */}
// {/* <Route path="/voice-broadcast/:id" element={<VoiceBroadcastView />} /> */}
