import { useEffect, useState, useRef, useMemo } from "react"
import { JService } from "../../api-service/ApiService"
import GModal from "../../design/components/GModal"
import styles from './MessageHistoryModal.module.css'
import { errorIcon, modalCloseIcon, topNavAccountPlusIcon } from "../../../utility-functions/assetLoader"
import { FormControl, InputAdornment, OutlinedInput, } from "@mui/material"
import { IconButton } from "rsuite"
import colors from "../../design/colors"
import Button from "../../design/Button"
import { useDispatch, useSelector } from "react-redux"
import { formatDate, capitalizeString, getOrgURLParam } from "../../../utility-functions"
import AnimationPage from "../../shared/animation-page/AnimationPage"
import { ScrollContainer } from "./ScrollContainer";
import { setCredits } from "../../features/account/account"
import searchIcon from '../../../assets/SearchIcon.svg';
import { createPortal } from "react-dom"
import libphonenumber from "google-libphonenumber"
import { enqueueSnackbar } from "notistack"
const formatTime24Hour=(date)=>{
    if(!date) return ''
    const dateObj=new Date(date)
    return `${dateObj.getHours()}:${dateObj.getMinutes()}`
}

const MessageHistoryModal=({visible, onClose, id, candidateDetails, viewOnly, viewedMsgId, payload})=>{
    const API_BASE_URL=process.env.REACT_APP_BETA

    const dispatch=useDispatch()

    const reduxAccount=useSelector(state=>state?.account?.value)
    const reduxVn =useSelector(state=>state?.virtualNumber?.value)
    const {canManageBillingSubscription,
        canViewSmsLogs,
        canSendSms,
        canAccessMessageTemplates } = useSelector((state) => state?.auth?.permissions);

    const [showLoader, setShowLoader]=useState(false)
    const [showTemplatesLoader, setShowTemplatesLoader]=useState(false);
    const [data, setData]=useState([])
    const [messages, setMessages]=useState({})
    const [isPostMessageLoading,setIsPostMessageLoading]=useState(false);
    const [msgText, setMsgText]=useState('')
    const [templateData, setTemplateData]=useState([])
    const [templateListVisible, setTemplateListVisible]=useState(false)
    const [displayForm, setDisplayForm]=useState(!viewOnly)
    const [curMessage, setCurMessage]=useState(viewedMsgId)
    const [from, setFrom] = useState(payload?.user_number)
    const [vnSearchText, setVnSearchText] =useState('')
    const [vnsToDisplay, setVnsToDisplay]=useState([])
    const [vnDropdownVisible, setVnDropdownVisible]=useState(false)

    const viewedMsgRef=useRef()

    const formatData=(messages)=>{
        const msgObj={}
        const today=formatDate(Date.now())
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday=formatDate(yesterday)
        messages.forEach(elem=>{
            let date=formatDate(elem.timestamp)
            if(date===today) date='Today'
            else if(date===yesterday) date='Yesterday'
            if(!msgObj[date]){
                msgObj[date]=[]
            }
            msgObj[date]?.push(elem)
        })
        setMessages(msgObj)
        
    }

    const getCountryCode = (phoneNumber) => {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        try {
          const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
          return number.getCountryCode()
        } catch (err) {
          console.log(err);
        }
      };
    
    
      const fetchData = async () => {
        if (!canViewSmsLogs) return;
        setShowLoader(true);
        let url = `/api/v1/core/retrieve-sms-interaction/`;
        if (id) {
          url += `?message_id=${id}`;
        }
        let _payload = {};
        if (payload && Object.keys(payload).length && !curMessage)
          _payload = { ...payload, user_number: from.country_code + from.number };
      
        JService.get(url, _payload)
          .then((res) => {
            if (!res?.success) {
              throw new Error(res?.message || "Could not retrieve message history");
            }
      
            setData(res?.data || []);
            formatData(res?.data?.reverse());

            if (!from && viewedMsgId) {
              setFrom(res?.data?.[0]?.user_number);
            }
      
            const userNumber =
              (from?.country_code + from?.number) ||
              res?.data?.[0]?.user_number;
            const contactNumber =
              payload?.contact_number || res?.data?.[0]?.contact_number;
      
            if (!canSendSms) {
              setDisplayForm(false);
              return;
            }
      
            const selectedVn = virtualNumbers.find(
              (vn) => vn.country_code + vn.number === userNumber
            );
            if (!selectedVn) {
              setDisplayForm(false);
              return;
            }
      
            const sameCountryCode =
              getCountryCode(userNumber) === getCountryCode(contactNumber);
            if (!sameCountryCode) {
              setDisplayForm(false);
              return;
            }
            setDisplayForm(!viewOnly && canSendSms);
          })
          .catch((err) => {
            enqueueSnackbar(err?.message, { variant: "error" });
          })
          .finally(() => {
            setShowLoader(false);
          });
      };

    const handleShowTemplates=()=>{
        if(!canAccessMessageTemplates){
            enqueueSnackbar('You do not have permission to view message templates', {variant: 'error'});
            return;
        }
        setTemplateListVisible(true)
        if(templateData && Array.isArray(templateData) && templateData.length) return;
        setShowTemplatesLoader(true);
        JService.get(API_BASE_URL + `/api/v1/core/view-message-templates`)
          .then((res) => {
            if (!res.success) {
              throw new Error(res?.message || "Could not fetch the templates. ");
            }
            setTemplateData(res?.data);
          })
          .catch((error) => {
            enqueueSnackbar(error?.message, {variant:'error'})
          })
          .finally(() => setShowTemplatesLoader(false));
    }
    useEffect(()=>{
        if(!visible) return;
        if(visible && !id && id!==0 && !payload) return;
        if(!from && payload) return;
        fetchData()
    },[id, visible, payload, from, canViewSmsLogs, canSendSms ])

    useEffect(()=>{
        if(viewedMsgId) {
            setCurMessage(viewedMsgId)
        }
    },[viewedMsgId])

    useEffect(()=>{
        if(payload)
        setFrom(payload.user_number)
    },[payload])

    const updateCreditsData=()=>{
        if(!canManageBillingSubscription) return;
        JService.get(API_BASE_URL+`/api/v1/subscriptions/credit/${getOrgURLParam()}`)
         .then((res)=>{
           if(!res?.success)
             throw new Error(res?.message || 'Failed to retrieve credits')
           if(res?.data && Object.keys(res?.data)?.length){
           dispatch(setCredits(res?.data))
           }
         }).catch(err=>{
         })
        }
    

    const handlePostMessage=(e, text)=>{
        if(!canSendSms) {
            enqueueSnackbar('You do not have permission to send SMS', {variant: 'error'});
            return;
        }
        const _msgText= text || msgText

        const payload={
            text:_msgText,
            contact_number:candidateDetails?.phone_number || messages?.[0]?.contact_number,
            user_number: typeof from==='string'? from : from.country_code+from.number
        }
        setIsPostMessageLoading(true)

        JService.post(API_BASE_URL+'/api/v1/core/send-sms/', JSON.stringify(payload))
        .then((res)=>{
            if(!res?.success) throw new Error(res?.message || 'Failed to send the message')
            enqueueSnackbar(res?.message, {variant:'success'})
            setCurMessage(null)
            fetchData()
        })
        .catch(err=>{
            enqueueSnackbar(err?.message, {variant:'error'})
        })
        .finally(()=>{
            setMsgText('')
            setIsPostMessageLoading(false)
            setTimeout(()=>{
                updateCreditsData()
            },1000)
        })

    }

    const handleSelectTemplate=(id, content)=>{
        setTemplateListVisible(false)
        const virtualNumber = reduxAccount?.data?.virtual_numbers?.[0]?.country_code + reduxAccount?.data?.virtual_numbers?.[0]?.number;
        const agentName = reduxAccount?.data?.first_name + ' ' + reduxAccount?.data?.last_name;
        const orgName=reduxAccount?.data?.profile?.org_name;
        const contactName=candidateDetails?.name || messages?.[0]?.contact_name
        const contactNumber=candidateDetails?.phone_number || messages?.[0]?.contact_number
        const text=content?.replaceAll('{contact_name}',contactName ?? '{contact_name}')
                           .replaceAll('{contact_phone_number}',contactNumber ?? '{contact_phone_number}' )
                           .replaceAll('{agent_name}', agentName ?? '{agent_name}')
                           .replaceAll('{agent_virtual_number}', virtualNumber ?? '{agent_virtual_number}')
                           .replaceAll('{organization_name}', orgName ?? '{organization_name}')
        setMsgText(text)
    }
    const handleCloseTemplateList=()=>{
        setTemplateListVisible(false)
    }
    const handleClose=()=>{
        setData([])
        setMessages([])
        setMsgText('')
        setTemplateData([])
        setTemplateListVisible(false)
        setShowLoader(false)
        setShowTemplatesLoader(false)
        setDisplayForm(false)
        setFrom(null)
        setCurMessage(null)
        setVnDropdownVisible(false)
        onClose()
    }

    const handleUnselectFromVn=()=>{
        setFrom(null)
    }

    const virtualNumbers = useMemo(() => {
        if (!reduxVn) return;
        const {
          outbound_virtual_numbers,
          inbound_virtual_numbers,
          team_virtual_numbers,
        } = reduxVn;
      
        const combinedInbound = [
          ...(inbound_virtual_numbers || []),
          ...(team_virtual_numbers || []),
        ];
      
        const intInbound = combinedInbound.filter((vn) => vn.country_code !== '+91');
        const smsEnabledNumbers = intInbound.filter((vn) =>
          outbound_virtual_numbers?.some((elem) => elem.id === vn.id)
        );
      
        setVnsToDisplay(smsEnabledNumbers);
        return smsEnabledNumbers;
      }, [reduxVn]);
      
    const handleVnSearchChange=(e)=>{
        const val=e.target.value;
       
        setVnSearchText(val)
        if(!val || !val.trim().length){
             setVnsToDisplay(virtualNumbers)
             return;
        }
        const temp=virtualNumbers?.filter((vn)=>{
            if(vn.name?.toLowerCase()?.includes(val?.toLowerCase())) return true;
            else if ((vn.country_code+vn.number)?.includes(val)) return true;
            else return false
        })
        setVnsToDisplay(temp)
    }

    const getNumberNameAndLocation=(number)=>{
        const numberObj=virtualNumbers?.find(vn=>vn.country_code+vn.number===number)
        if(numberObj){
            return `${numberObj?.name} (${capitalizeString(numberObj?.location)})`
        }
        else 
        return number
    }
    const smsInteractionModalBody=(
        <div className={styles.container}>
            <div  className={`${templateListVisible && styles.overlay}`}></div>
            {/* <div ref={ref} className={`${styles.msgContainer}`}> */}
            <ScrollContainer scrollCta={'new message'} displayForm={displayForm} viewedMsgRef={viewedMsgRef}>
            {showLoader && <AnimationPage/>}
            {!showLoader && Object.keys(messages)?.map(date=>{
                const len=messages[date]?.length
                const messageElems= messages[date]?.map((msg, idx)=>{
                  let nextMsg = idx < (len - 1) ? messages[date][idx + 1] : null;
                  let prevMsg = idx > 0 ? messages[date][idx - 1] : null;
                  
                  const lastOfGroup =
                    !nextMsg ||
                    nextMsg.direction !== msg.direction ||
                    (msg.direction === "OUTBOUND" && nextMsg.creator_name !== msg.creator_name) ||
                    (msg.direction === "INBOUND" && nextMsg.contact_name !== msg.contact_name);
                  
                  const firstOfGroup =
                    !prevMsg ||
                    prevMsg.direction !== msg.direction ||
                    (msg.direction === "OUTBOUND" && prevMsg.creator_name !== msg.creator_name) ||
                    (msg.direction === "INBOUND" && prevMsg.contact_name !== msg.contact_name);
                  
                    const curMsgTime=formatTime24Hour(msg.timestamp)
                    const nextMsgTime=formatTime24Hour(nextMsg?.timestamp)
                      return (
                        <div
                        key={msg.id}
                        ref={curMessage === msg.id ? viewedMsgRef : null}
                        className={styles.msgAndErrContainer}
                        style={{
                            marginTop: firstOfGroup? "20px" : "6px",
                            marginBottom: lastOfGroup ? "20px" : "6px",
                        }}
                        >
                      {msg.direction === "OUTBOUND" && msg.creator_name && firstOfGroup && (
                        <div className={styles.contactHeader}>
                          <span className="t8 medium-font">
                            {msg.creator_name || "Unknown"}
                          </span>
                          <div className={styles.avatarLetter}>
                          {(msg.creator_name || "U").charAt(0).toUpperCase()}
                          </div>
                        </div>
                      )}

                      {msg.direction === "INBOUND" && firstOfGroup && (
                        <div className={styles.contactHeaderInbound}>
                          <div className={styles.avatarLetterInbound}>
                            {(msg.contact_name || "U").charAt(0).toUpperCase()}
                          </div>
                          <span className="t8 medium-font">
                            {msg.contact_name || "Unknown"}
                          </span>
                        </div>
                      )}

                        <div className={styles[`${msg.direction}_msg`]}>
                            {nextMsg && curMsgTime === nextMsgTime && msg.direction === nextMsg.direction
                            ? null
                            : <span className={`${styles.time} t8 regular-font`}>{curMsgTime}</span>}
                            <div className={`${curMessage === msg.id && styles.viewedMsg} ${styles.content}`}>
                            <p className="t7 regular-font">{msg.content}</p>
                            </div>
                        </div>
                        {msg.direction === "OUTBOUND" && msg.status === "failed" && (
                            <div className={styles.failedMsg}>
                            <span>{errorIcon(colors.ic_red_400)}</span>
                            <span className="t8 regular-font">Sending failed.</span>
                            <span
                                className={`t8 regular-font ${styles.retryBtn}`}
                                onClick={(e) => handlePostMessage(e, msg.content)}
                            >
                                Retry
                            </span>
                            </div>
                        )}
                        </div>
                    );
                })
               
                return (
                    <>
                    <div className={styles.dateContainer} key={date}>
                        <span className={`${styles.date} t8 regular-font`}>{date}</span>
                    </div>
                    {messageElems}
                    </>
                )
            })}
            <div  style={{width:'1px', height:'1px'}}></div>
            {/* </div> */}
            </ScrollContainer>
            <div className={styles.listAndFormContainer} style={{borderRadius:templateListVisible ? '20px 20px 0 0':''}}>
                {templateListVisible && (
                    <div className={styles.templateListContainer}>
                        <div className={styles.listHeader}><span className="t7 medium-font">Message Templates</span><span className={styles.closeIcon} onClick={handleCloseTemplateList}>{modalCloseIcon()}</span></div>
                        <ul>
                            {showTemplatesLoader && <AnimationPage/>}
                            {!showTemplatesLoader && templateData?.map((elem)=>{
                                return (
                                    <li className='t7 regular-font' key={elem.id} onClick={()=>handleSelectTemplate(elem.id,elem.body)}>
                                        {elem.title}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )}
                 <form className={styles.vnForm}>
                    <label className="t7 medium-font">From: </label>
                    {from && <div className={styles.chip}><span className="t7 regular-font">{typeof from === 'object'?`${from?.name} (${capitalizeString(from?.location)})`:getNumberNameAndLocation(from)}</span>{!viewedMsgId && <span className={styles.closeIcon} onClick={handleUnselectFromVn}>{modalCloseIcon('#464d48','12','12')}</span>}</div>}
                    {!from && !viewedMsgId && (
                        <div className={styles.vnDropdownContainer}>
                            <p className='t7 regular-font' onClick={()=>setVnDropdownVisible(prev=>!prev)}>Select virtual number</p>
                         {vnDropdownVisible && createPortal(<div className={styles.vnDropdown} style={{bottom:displayForm?'calc(22.5vh - 90px)':'calc(22.5vh - 115px)'}}>
                           <div className="dialer--cc_search" >
                          <img src={searchIcon} alt="" />
                            <input
                            type="search"
                            value={vnSearchText}
                            onChange={handleVnSearchChange}
                            placeholder="Search"
                            />
                       </div>
                       <ul>
                        {vnsToDisplay?.map((vn)=>{
                            return <li key={vn.id} onClick={()=>{setVnDropdownVisible(false);setFrom(vn)}}> <div style={{ padding: "16px 12px" }}>
                            <p
                              style={{ margin: 0 }}
                              className="t7 regular-font nc-gray-900"
                            >
                              {vn?.name} ({capitalizeString(vn.location)})
                            </p>
                            <p
                              style={{ margin: 0 }}
                              className="t7 regular-font nc-gray-600"
                            >
                              {vn?.country_code + " " + vn?.number}
                            </p>
                          </div></li>
                        })}
                       </ul>
                        </div>, document.querySelector('.gmodal-background'))}
                        </div>
                    )}

                </form>
                {displayForm && <form className={styles.form}>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                {/* <InputLabel shrink={false} htmlFor="outlined-adornment-password">Enter message</InputLabel> */}
                <OutlinedInput
                   multiline
                   minRows={1}
                   maxRows={4}
                    inputProps={{sx:{
                        padding:'7px 10px 7px 12px',
                        fontSize:'14px',
                        fontFamily:'Inter',
                        color:colors.nc_gray_900,
                        lineHeight:'160%'
                       
                    }}}
                    sx={{ borderRadius:'4px',
                    padding:'0 10px 0 0',
                    alignItems:'flex-end',
                    '&:focus':{
                        border:'1px solid #e1e5e2'
                    },
                    '.Mui-focused':{
                        border:'1px solid #e1e5e2'
                    },
                    "& .MuiInputBase-root": {
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        flexWrap: "nowrap",
                        alignItems: "flex-end",
                        maxWidth: "360px",
                        padding:0,
                        ".Mui-focused:": {
                          border: `1px solid ${colors.ic_green_500}`,
                        },
                      },
                      "& .MuiInputBase-root-MuiOutlinedInput-root":{
                        padding:0
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${colors.nc_gray_300}`,
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${colors.nc_gray_300}`,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${colors.ic_green_300}`,
                       
                      },
                    
                }}
                    id="outlined-adornment-password"
                    value={msgText}
                    onChange={(e)=>setMsgText(e.target.value)}
                    disabled={isPostMessageLoading}
                    endAdornment={ canAccessMessageTemplates ?
                    <InputAdornment position="end"  sx={{height:'2em'}}>
                        <IconButton
                            aria-label="open templates list"
                            onClick={handleShowTemplates}
                            disabled={templateListVisible}
                            edge="end"
                            style={{
                                backgroundColor:'#fff',
                                padding:'0',
                                lineHeight:'16px'
                            }}
                            >
                            {(templateListVisible || isPostMessageLoading) ? topNavAccountPlusIcon('#c6ccc8') : topNavAccountPlusIcon('#5f6661')}
                        </IconButton>
                    </InputAdornment>
                    : null}
                    placeholder="Enter message"
                    // label="Enter message"
                />
                </FormControl>
                <div>
                {msgText && msgText.length>0 && <p style={{marginBottom:'4px'}} className="t8 regular-font">{`${msgText?.length}/${Math.ceil(msgText.length/160)}`}</p>}
                <Button buttonText='Send' disabled={!msgText || !msgText.trim().length || !from} hierarchy='green' onClick={handlePostMessage} isLoading={isPostMessageLoading} styleOverride={{height:'36px', width:'67px', fontSize:'14px', fontWeight:'500'}}/>
                </div>
                </form>}
            </div>
        </div>
    )
    return (
    <GModal noScrollbar={true} visible={visible} closeModal={handleClose} heading={candidateDetails?.name || messages?.[0]?.contact_name || 'Unknown'} bottomButtons={[]} body={smsInteractionModalBody}
    modalStyle={{padding:'0', marginBottom:0}}
    bodyWrapperStyle={{width:'488px', paddingRight:0}}
    />
    )
}

export default MessageHistoryModal