import { useEffect, useReducer, useState } from "react"
import GModal from "../../design/components/GModal"
import GTable from '../../design/components/GTable'
import { JService } from "../../api-service/ApiService"
import Button from "../../design/Button"
import styles from './CreditHistoryModal.module.css'
import { filtersReducer, formatDate, getOrgURLParam } from "../../../utility-functions"
import { Filters } from "../../dashboard/Filters"
import '../../call-logs/callLogsStyles.css'
import { useSelector } from "react-redux"
import { enqueueSnackbar } from 'notistack'

const emptyState={
  categories: {
    all: [
    {id:1, name:'Credits'},
    {id:2, name:'Subscriptions' },
    {id:3, name: 'Calls and Transcriptions'}
    ],
    selected: [],
  },
  searchText:''
}
const formatCalendarDate = (date) => {
  return `${new Date(date).getFullYear()}-${(
    "0" +
    (new Date(date).getMonth() + 1)
  ).slice(-2)}-${("0" + new Date(date).getDate()).slice(-2)}`;
};

const CreditHistoryModal=({visible,handleClose,currency})=>{
    const reduxAccount = useSelector((state) => state?.account?.value);

    const [data,setData]=useState([])
    const [rows,setRows]=useState([])
    const [pageData,setPageData]=useState({rowCount:0,prev:null,next:null,curPage:1,pageSize:10})
    const [gTablePageNumber, setGTablePageNumber] = useState(0);
    const [calendar,setCalendar]=useState({date_start: formatCalendarDate(Date.now() - 7*86400000), // 2 days data
                                           date_end: formatCalendarDate(Date.now())})
    const [showLoader,setShowLoader]=useState(false)
    const [csvData, setCSVData] = useState({
      headers: [
        ["Date"],
        ["Description"],
        ["Amount"],
      ],
      data: [],
      ids: [],
    });

    const [availableData, dispatchAvailableData] = useReducer(
      filtersReducer,
      emptyState
    );
    
    const handleFilterApply = (filterName, val) => {
      if (filterName === "Date") {
         setPageData(prev=>({...prev,curPage:1}))
         setGTablePageNumber(0)
         setCalendar({date_start:val?.date_start,date_end:val?.date_end});
        
        }
        
      };
      const handlePageChange=(val)=>{
        setPageData(prev=>({...prev,curPage:val+1}))
    }
    const handlePageSizeChange=(pageSize)=>{
        setPageData(prev=>({...prev,pageSize:pageSize,curPage:1}))
        setGTablePageNumber(0)
    }
    const onSearch = (searchText) => {
      if(!searchText || !searchText.trim()?.length){
        setRows(data);
        return
      } 
      const searchTextLower = searchText?.toLowerCase();
      const temp = data?.filter((row) =>
        row.description?.toLowerCase().includes(searchTextLower)
      );
      setRows(temp)
    };
    const fetchCreditHistory=()=>{
        setShowLoader(true)
        let url=`/api/v1/subscriptions/credit-history/${getOrgURLParam()}?date_start=${calendar?.date_start}&date_end=${calendar?.date_end}&page=${pageData?.curPage}&page_size=${pageData?.pageSize}`
        if(availableData?.categories?.selected?.length)
          url+=`&categories=[${availableData?.categories?.selected}]`
        JService.get(url)
        .then((res)=>{
            if(!res.success){
                throw new Error(res?.message || 'Error on fetching credit history')
            }
            setData(res?.data?.results?.map((row,index)=>({...row,id:index})))
            setRows(res?.data?.results?.map((row,index)=>({...row,id:index})))
            setPageData(prev=>({...prev,rowCount:res?.data?.count,next:res?.data?.next,prev:res?.data?.prev}))
        })
        .catch((err)=>{
            setData([])
            setRows([])
			enqueueSnackbar(err?.message, { variant: "error"})
        })
        .finally(()=>{
          setShowLoader(false)
        })
    }
    useEffect(()=>{
        if(!visible) return;
        fetchCreditHistory()
    },[visible,pageData.pageSize,pageData.curPage,calendar,availableData?.categories?.selected])

    useEffect(() => {
      onSearch(availableData?.searchText);
    }, [availableData.searchText]);
    
    useEffect(() => {
      setCSVData((prevState) => {
        let newIds = [];
        let newData = [];
  
        for (let i = 0; i < rows.length; i++) {
          let item = rows[i];
          if (!newIds?.includes(item?.id)) {
            newIds.push(item?.id);
            let temp = [
              item.date,
              item.description,
              `${currency}${item.amount}`,
            ];
            newData.push(temp);
          }
        }
        return { ...prevState, data: newData, ids: newIds };
      });
    }, [rows]);
  
    const cols= [
        {
          field: "date",
          headerName: "Date",
          flex: 31.25,
          headerClassName:styles.headerClass,
          renderCell:(params)=><span className="t7 regular-font" style={{paddingLeft:'14px'}}>{formatDate(params?.row?.date)}</span>
        },
        {
            field:'description',
            headerName:'Description',
            flex:31.25,
            headerClassName:styles.headerClass,
            renderCell:(params)=><span className="t7 regular-font">{params?.row?.description}</span>
        },
        // {
        //     field:'view',
        //     headerName:'',
        //     flex:12.5,
        //     renderCell:(params)=>{['Incoming call','Outgoing call'].includes(params?.row?.description) && <span className={styles.viewCreditBtnContainer}><button className="b2 medium-font" type='button'>View</button></span>}
        // },
        {
            field:'amount',
            headerName:'Amount',
            flex:25,
            headerClassName:styles.headerClass,
            renderCell:(params)=>{
              let transactionType=['Plan credits','Credits recharge','Trial credits'].includes(params?.row?.description)?'credit':'debit'
              return transactionType==='credit'?<span className="t7 regular-font" style={{color:'#2eb258'}}>+{currency}{params.row.amount}</span>:<span>{currency}{params.row.amount}</span>
            }
        }
    ]
    return (
        <GModal
            visible={visible}
            closeModal={handleClose}
            bottomButtons={[]}
            bodyWrapperStyle={{width:'700px'}}
            modalStyle={{padding:'32px 32px 40px',maxHeight:'80vh'}}
            heading='Credits usage history'
            body={
                <div style={{marginBottom:'40px',maxHeight:'calc(80vh - 88px)'}}>
                     <Filters
                        style={{marginTop:'0'}}
                        onApply={handleFilterApply}
                        fromScreen="credit-history"
                        defaultDateRange={7}
                        availableData={availableData}
                        dispatchAvailableData={dispatchAvailableData}
                        filtersList={[
                         {name:'Category', isSearch: false, payloadFor: 'categories'}
                        ]}
                      />
                    <GTable
                        className="call-logs-table"
                        leftHeader={{
                            filters: {},
                            isSearch:true
                          }}
                          rightHeader={{isRefresh: true, isDownload: true }}
                          download={{
                            data: csvData,
                            body: null,
                            filename:
                              `credit-usage-` +
                              new Date().toLocaleString("en-US", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }),
                          }}
                          rows={rows}
                          columns={cols}
                          useMuiTable={true}
                          isLoading={showLoader}
                          //getTableRowClassName={() => `teams-table-row`}
                          //additionalProps={{ bgHover: true }}
                          onRowClick={() => null}
                          onSearch={onSearch}
                          fromScreen="credit-history"
                           rowCount={pageData?.rowCount || 0}
                           onPageChange={handlePageChange}
                           onPageSizeChange={handlePageSizeChange}
                          // rowsPerPage={pageSize}
                           //gTablePageNumber={gTablePageNumber}
                          setGTablePageNumber={setGTablePageNumber}
                          availableData={availableData}
                          dispatchAvailableData={dispatchAvailableData}
                          paginationMode="server"
                        />
                </div>
            }
        />
    )
}

export default CreditHistoryModal