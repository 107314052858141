import { store } from "../../.."
import { setPermissions } from "./permission";
import { setIsOwner, setMaskNumbers, setValidPaths } from "./permission";

export const hasPermission = (category='', action='', userId=null, teamId=null) => {
    if(!store) return;
    const userRoles = store?.getState()?.auth?.userRoles;
    const result= userRoles?.some(role => {
       return role?.permissions?.find(elem => {
            return (category ? elem.category === category : true) && (action ? elem.action === action : true)
        })
          &&
        isInScope(role.scope, userId, teamId);
    });
    return result;
}

const isInScope = (scope, userId, teamId) => {
    if(scope.scope_type == 1 || (!userId && !teamId)) return true;
    if(scope.scope_type == 2){
        if(userId === store?.getState()?.account?.value?.data?.profile?.user)   
            return true;
        return false;
    }
    if(scope.scope_type == 3){
        if(userId){
            if(scope.users.find(u => u.id === userId)) return true;
            if(scope.teams?.find(t => t?.member_ids?.includes(userId))) return true;
        } 
        if(teamId && scope.teams?.find(t => t.id === teamId)) return true;
        return false;
    }
    return false;
}

export const storePermissions = () => {
    const accessList = store.getState()?.account?.value?.data?.access_list
    const permissions = {
        canAccessWebApp: hasPermission('product access', 'web app'),
        canViewCallLogs: hasPermission('calling', 'call logs'),
        canViewSmsLogs: hasPermission('sms', 'sms logs'),
        canCreateEditUsers: hasPermission('users and roles', 'create and edit users'),
        canViewCallRecording: hasPermission('calling', 'call recording'),
        canViewContactsAndLists: hasPermission('contacts', 'view contacts and lists'),
        canCreateEditContactsAndLists: hasPermission('contacts', 'create and edit contacts and lists'),
        canDeleteContactsAndLists: hasPermission('contacts', 'delete contacts and lists'),
        canAssignUnassignContacts: hasPermission('contacts', 'assign/unassign contacts'),
        canManage2FA: hasPermission('org level settings', 'manage 2fa'),
        canManagePrivateRecording: hasPermission('org level settings', 'manage private recordings'),
        canManageRecordWarning: hasPermission('org level settings', 'manage record warning'),
        canManageReports: hasPermission('usage metrics', 'email reports'),
        canManageCallDispositions: hasPermission('', 'manage call dispositions'),
        canManageOrgLevelIntegrations: hasPermission('org level settings', 'manage org level integrations'),
        canManageBillingSubscription: hasPermission('billing and subscriptions', 'manage billing and subscriptions'),
        canSendSurvey: hasPermission('surveys', 'send surveys') && accessList?.includes('IVR SURVEY'),
        canViewUsers: hasPermission('users and roles', 'view users'),
        canCreateEditUsers: hasPermission('users and roles', 'create and edit users'),
        canDeleteUsers: hasPermission('users and roles', 'delete users'),
        canViewTeams: hasPermission('teams', 'view teams') && accessList?.includes('TEAMS'),
        canCreateEditTeams: hasPermission('teams', 'create and edit teams') && accessList?.includes('TEAMS'),
        canDeleteTeams: hasPermission('teams', 'delete teams') && accessList?.includes('TEAMS'),
        canManageRoles: hasPermission('users and roles', 'manage roles'),
        canManageNumbers: hasPermission('numbers', 'manage numbers'),
        canAccessMessageTemplates: hasPermission('sms', 'message templates'),
        canSendSms: hasPermission('sms', 'send sms'),
        canTriggerSms: hasPermission('sms', 'send sms') && accessList?.includes('SMS TRIGGER'),
        canMakeCalls: hasPermission('calling', 'outbound calls'),
        canDownloadCallLogsAndRecording: hasPermission('calling', 'download'),
        canBlockCandidates: hasPermission('org level settings', 'manage block list'),
    }
    console.log('storing permissions, canAccessWebApp', permissions.canAccessWebApp);
    store.dispatch(setPermissions(permissions));

    const userRoles = store?.getState()?.auth?.userRoles;
    const isOwner = userRoles.some(role => role.title === 'Owner' && !role.is_custom)
    store.dispatch(setIsOwner(isOwner));

    const account = store?.getState()?.account?.value;
    store.dispatch(setMaskNumbers(account?.data?.number_masking && !isOwner));
}

export const storeValidPaths = () => {
    const accessList = store.getState()?.account?.value?.data?.access_list
    const tabs = {
        "Call logs": {allowed: hasPermission('', 'call logs') || hasPermission('', 'sms logs'), path: hasPermission('', 'sms logs') ? hasPermission('', 'call logs') ? "/activity-logs/calls" : "/activity-logs/messages": "/call-logs" },
        "Contact lists": {allowed: hasPermission('contacts'), path: "/contact-lists"},
        "Campaigns": {allowed: accessList?.includes("AUTODIAL") || (accessList?.includes("IVR SURVEY") && hasPermission('surveys', 'manage surveys'))},
        "Autodial": {allowed: accessList?.includes("AUTODIAL"), path: "/autodial"},
        "Survey": {allowed: hasPermission('surveys', 'manage surveys') && accessList?.includes('IVR SURVEY'), path: "/survey"},
        "Users & Teams": {allowed: hasPermission('users and roles') || (hasPermission('teams') && accessList?.includes('TEAMS')), path: "/users-and-teams"},
        "Templates": {allowed: hasPermission('', 'message templates'), path: "/messages"},
        "Virtual numbers": {allowed: hasPermission('numbers'), path: "/virtual-numbers"},
        "Billing": {allowed: hasPermission('billing and subscriptions'), path: "/billing"},
        "Settings" : {allowed: true, path: "/settings"},
        "Dashboard": {allowed: hasPermission('', 'dashboard'), path: "/dashboard"},
      }
    console.log('storing valid paths')
    store.dispatch(setValidPaths(tabs));
}